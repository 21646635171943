import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { saveOnboardingData } from '../store/reducers/onboardingSlice'; // Import the new action
import { useNavigate } from 'react-router-dom';

const OnboardingPage = () => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.onboarding);

  // Yup validation schema
  const validationSchema = Yup.object({
    company_name: Yup.string().required('Company name is required'),
    company_brief: Yup.string().required('Company brief is required'),
    industry: Yup.string().required('Industry is required'),
    email_id: Yup.string().email('Invalid email address').required('Email ID is required'),
  });
  const handleSignInClick = () => {
    navigate('/signin');
  };

  // Formik setup
  const formik = useFormik({
    initialValues: {
      company_name: '',
      company_brief: '',
      industry: '',
      email_id: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        // Dispatch a single action to handle both fetching and creating data
        await dispatch(saveOnboardingData(values));
        
        // Navigate to the dashboard
        navigate('/dashboard');
      } catch (error) {
        console.error('Failed to save onboarding data:', error);
      }
    },
  });

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 py-6 text-gray-300">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Welcome to Your Business Onboarding!</h2>

        {error && <div className="text-red-500 mb-4">{error}</div>}

        <form onSubmit={formik.handleSubmit}>
          {/* Company Name */}
          <label className="block mb-2">Company Name</label>
          <input
            type="text"
            name="company_name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company_name}
            className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.company_name && formik.errors.company_name ? 'border-red-500' : 'border-gray-600'}`}
            placeholder="Enter your company name"
          />
          {formik.touched.company_name && formik.errors.company_name && (
            <span className="text-red-400">{formik.errors.company_name}</span>
          )}

          {/* Company Brief */}
          <label className="block mb-2">Company Brief</label>
          <textarea
            name="company_brief"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.company_brief}
            className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.company_brief && formik.errors.company_brief ? 'border-red-500' : 'border-gray-600'}`}
            placeholder="Enter a brief description of your company"
          />
          {formik.touched.company_brief && formik.errors.company_brief && (
            <span className="text-red-400">{formik.errors.company_brief}</span>
          )}

          {/* Industry */}
          <label className="block mb-2">Industry</label>
          <input
            type="text"
            name="industry"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.industry}
            className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.industry && formik.errors.industry ? 'border-red-500' : 'border-gray-600'}`}
            placeholder="Enter your industry"
          />
          {formik.touched.industry && formik.errors.industry && (
            <span className="text-red-400">{formik.errors.industry}</span>
          )}

          {/* Email ID */}
          <label className="block mb-2">Email ID</label>
          <input
            type="email"
            name="email_id"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email_id}
            className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.email_id && formik.errors.email_id ? 'border-red-500' : 'border-gray-600'}`}
            placeholder="Enter your email ID"
          />
          {formik.touched.email_id && formik.errors.email_id && (
            <span className="text-red-400">{formik.errors.email_id}</span>
          )}

          {/* Submit Button */}
          <div className="mt-6 flex justify-between items-center">
            <button
              type="submit"
              disabled={loading || !formik.isValid || !formik.dirty}
              className={`bg-customOrange-button text-black px-4 py-2 rounded ${
                loading || !formik.isValid || !formik.dirty ? 'bg-opacity-50 cursor-not-allowed' : 'hover:bg-customOrange-text hover:text-white'
              }`}
            >
              {loading ? 'Submitting...' : 'Finish'}
            </button>
            <button
              type="button"
              onClick={handleSignInClick}  // Navigate to sign-in page
              className="text-customOrange-400 hover:underline"
            >
              Already have an account? Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OnboardingPage;