import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { fetchQuery } from '../helpers';

const AGENT_DETAILS_QUERY = `
  query MyQuery($id: uuid = "") {
    vocallabs_agent_by_pk(id: $id) {
      language
      purpose
    }
  }
`;

const TestApiPage = () => {
  const agents = useSelector((state) => state.agents?.agents || []).filter(agent => agent.purpose === 'api');

  const [language, setLanguage] = useState("hi");
  const [number, setNumber] = useState("+919182517283");
  const [ttsProvider, setTtsProvider] = useState("google");
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [agentId, setAgentId] = useState(agents[0]?.id || '');

  const handleAgentChange = async (e) => {
    const selectedAgentId = e.target.value;
    setAgentId(selectedAgentId);

    if (selectedAgentId) {
      try {
        const data = await fetchQuery(AGENT_DETAILS_QUERY, { id: selectedAgentId });
        const agentDetails = data.vocallabs_agent_by_pk;

        // Update the state with fetched agent details
        if (agentDetails) {
          setLanguage(agentDetails.language || '');
        }
      } catch (err) {
        setError('Error fetching agent details: ' + err.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    const data = {

      language: language,
      number: number,
      tts_provider: ttsProvider,
      agent_id: agentId,
    };

    try {
      const result = await axios.post('https://api.vocallabs.ai/create-call-via-api', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setResponse(result.data);
    } catch (err) {
      setError('Error: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h1 className="text-xl font-bold mb-4">Test API Call</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="agentSelect">Select Agent</label>
            <select
              id="agentSelect"
              value={agentId}
              onChange={handleAgentChange}
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              required
            >
              <option value="">Select an Agent</option>
              {agents.map((agent) => (
                <option key={agent.id} value={agent.id}>
                  {agent.name || `Agent ${agent.id}`}
                </option>
              ))}
            </select>
          </div>
          
        
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="language">Language</label>
            <input
              type="text"
              id="language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="number">Number</label>
            <input
              type="text"
              id="number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              required
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700" htmlFor="ttsProvider">TTS Provider</label>
            <input
              type="text"
              id="ttsProvider"
              value={ttsProvider}
              onChange={(e) => setTtsProvider(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full text-white rounded p-2 ${loading ? 'bg-gray-400' : 'bg-blue-500'}`}
            disabled={loading}
          >
            {loading ? 'Making Call...' : 'Make Call'}
          </button>
        </form>
        {loading && (
          <div className="mt-4 text-center">
            <p className="text-gray-600">Loading...</p>
          </div>
        )}
        {response && (
          <div className="mt-4 p-2 border border-gray-300 rounded bg-gray-50">
            <h2 className="font-semibold">Response:</h2>
            <pre>{JSON.stringify(response, null, 2)}</pre>
          </div>
        )}
        {error && (
          <div className="mt-4 p-2 border border-red-300 rounded bg-red-50 text-red-700">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestApiPage;
