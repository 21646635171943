import React, { useState } from 'react';

const TextToSpeechAzure = () => {
  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const [inputType, setInputType] = useState('text'); // 'text' or 'ssml'
  const [serviceType, setServiceType] = useState('google'); // 'google' or 'azure'
  
  const apiKey = 'AIzaSyArmNJavKEDdcu2ZAOXurisKKtW8gjMffI'; // Replace with your Google API key
  const azureSubscriptionKey = 'f74253b96e694800a6df60c6e1f89a6b'; // Replace with your Azure subscription key
  const azureRegion = 'swedencentral'; // Replace with your Azure region

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async () => {
    let requestBody;

    if (inputType === 'text') {
      requestBody = {
        input: { text: text },
        voice: {
          languageCode: 'en-US', // Update as needed
          name: 'en-US-Standard-B', // Update as needed
          ssmlGender: 'MALE',
        },
        audioConfig: {
          audioEncoding: 'LINEAR16',
          sampleRateHertz: 16000,
        },
      };
    } else {
      requestBody = {
        input: { ssml: text },
        voice: {
          languageCode: 'en-US', // Update as needed
          name: 'en-US-Standard-B', // Update as needed
          ssmlGender: 'MALE',
        },
        audioConfig: {
          audioEncoding: 'LINEAR16',
          sampleRateHertz: 16000,
        },
      };
    }

    try {
      let response;
      if (serviceType === 'google') {
        response = await fetch(
          `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          }
        );
      } else {
        // For Azure TTS
        const azureUrl = `https://${azureRegion}.tts.speech.microsoft.com/cognitiveservices/v1`;
        const azureRequestBody = `<speak version='1.0' xml:lang='en-US'>
          <voice xml:lang='en-US' xml:gender='Female' name='en-US-AvaNeural'>${text}</voice>
        </speak>`;

        response = await fetch(azureUrl, {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': azureSubscriptionKey,
            'Content-Type': 'application/ssml+xml',
            'X-Microsoft-OutputFormat': 'audio-16khz-128kbitrate-mono-mp3',
          },
          body: azureRequestBody,
        });
      }

      const data = await response.json();
      if (serviceType === 'google' && data.audioContent) {
        const audioBlob = new Blob([Uint8Array.from(atob(data.audioContent), c => c.charCodeAt(0))], {
          type: 'audio/wav',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      } else if (serviceType === 'azure' && response.ok) {
        const audioBlob = await response.blob();
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      } else {
        console.error('Error:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Text to Speech</h1>
      <select onChange={(e) => setInputType(e.target.value)} value={inputType}>
        <option value="text">Text Input</option>
        <option value="ssml">SSML Input</option>
      </select>
      <select onChange={(e) => setServiceType(e.target.value)} value={serviceType}>
        <option value="google">Google TTS</option>
        <option value="azure">Azure TTS</option>
      </select>
      <br />
      <textarea
        value={text}
        onChange={handleInputChange}
        rows={5}
        cols={50}
        placeholder={inputType === 'ssml' ? "Enter SSML here..." : "Enter text here..."}
      />
      <br />
      <button onClick={handleSubmit}>Convert to Speech</button>

      {audioUrl && (
        <div>
          <h2>Generated Speech:</h2>
          <audio controls src={audioUrl} />
        </div>
      )}
    </div>
  );
};

export default TextToSpeechAzure;
