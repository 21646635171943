import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchQuery } from '../helpers/index'; // Assuming fetchQuery is imported from helpers

const TestPromptScreen = () => {
  const [prompt, setPrompt] = useState('');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [temperature, setTemperature] = useState(0.2); // Default value
  const [messages, setMessages] = useState([]); // Array to store both user and assistant messages
  const [loading, setLoading] = useState(false);
  const [dataReceived, setDataReceived] = useState(0);
  const [useFirstEndpoint, setUseFirstEndpoint] = useState(true);

  // Fetch available agents from Redux or another source
  const agents = useSelector((state) => state.agents?.agents || []).filter(agent => agent.purpose === 'api');

  // GraphQL query to fetch chat settings based on selected agent ID
  const GET_CHAT_SETTINGS = `
    query MyQuery($id: uuid = "") {
      vocallabs_chatSettings(where: {agent_id: {_eq: $id}}) {
        firstmessage
        id
        prompt
        promptforactioncode
        promptforanalytics
      }
    }
  `;

  useEffect(() => {
    const fetchChatSettings = async () => {
      if (selectedAgent) {
        try {
          const data = await fetchQuery(GET_CHAT_SETTINGS, { id: selectedAgent.id });
          if (data && data.vocallabs_chatSettings.length > 0) {
            const agentSettings = data.vocallabs_chatSettings[0];
            setMessages([
              { role: 'system', content: agentSettings.prompt || '' },
              { role: 'assistant', content: agentSettings.firstmessage || '' }
            ]);
          }
        } catch (error) {
          console.error('Error fetching agent settings:', error);
        }
      }
    };

    fetchChatSettings();
  }, [selectedAgent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return; // Prevent empty submissions
    setLoading(true);

    // Add user's message to the conversation
    const newMessages = [...messages, { role: 'user', content: prompt }];
    setMessages(newMessages);
    setPrompt(''); // Clear input

    // Prepare the API request payload
    const requestData = {
      messages: newMessages.map(({ role, content }) => ({ role, content })), // Use the conversation history
      temperature: parseFloat(temperature),
    };
    const endpoint = useFirstEndpoint
      ? "https://subspacellm.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-08-01-preview"
      : "https://subspacellm.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-08-01-preview";

    const openaiendpoint = "https://api.openai.com/v1/chat/completions";

    const openairequestdata = {
      "model": "gpt-4o-mini",
      messages: newMessages.map(({ role, content }) => ({ role, content })), // Use the conversation history
      temperature: parseFloat(temperature),
    };

    try {
      const res = await fetch(openaiendpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer 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",
        },
        body: JSON.stringify(openairequestdata),
      });
      setUseFirstEndpoint(false);
      const responseData = await res.json();

      if (responseData && responseData.choices && responseData.choices.length > 0) {
        const assistantMessage = responseData.choices[0].message.content;
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: assistantMessage },
        ]);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: 'No valid response received from the API.' },
        ]);
      }
    } catch (error) {
      console.error('Error fetching API:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Failed to fetch response.' },
      ]);
    } finally {
      setLoading(false);
    }
  };

  // const getActionCode = async (e) => {
  //   e.preventDefault();
  //   if (!selectedAgent) return;

  //   const actionMessages = messages.map(message => {
  //     if (message.role === 'system') {
  //       return { ...message, content: data.vocallabs_chatSettings[0].promptforanalytics }; // Use promptforanalytics for system message
  //     }
  //     return message;
  //   });

  //   const requestData = {
  //     "model": "gpt-4o-mini",
  //     messages: actionMessages.map(({ role, content }) => ({ role, content })),
  //     temperature: parseFloat(temperature),
  //     response_format: { "type": "json_object" }
  //   };

  //   try {
  //     const res = await fetch('https://api.openai.com/v1/chat/completions', {
  //       method: 'POST',
  //       headers: {
  //         "Authorization": "Bearer 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",
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     const responseData = await res.json();
  //     if (responseData && responseData.choices && responseData.choices.length > 0) {
  //       const assistantMessage = responseData.choices[0].message.content;
  //       console.log("data extracted is", assistantMessage);
  //       console.log(JSON.parse(assistantMessage));
  //       setDataReceived(JSON.parse(assistantMessage));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Left Side: Chat UI */}
      <div className="w-2/3 p-6 bg-white border-r">
        <h2 className="text-xl font-bold mb-4">Chat API</h2>

        {/* Agent Selection */}
        <div className="mb-4">
          <label htmlFor="agent-select" className="block text-sm font-medium text-gray-700">Select Agent</label>
          <select
            id="agent-select"
            className="mt-2 p-2 border rounded w-full"
            onChange={(e) => {
              const selectedAgentId = e.target.value;
              const agent = agents.find(a => a.id === selectedAgentId);
              setSelectedAgent(agent);
            }}
            value={selectedAgent ? selectedAgent.id : ''}
          >
            <option value="">Select an agent</option>
            {agents.map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
          </select>
        </div>

        {/* Conversation History */}
        <div className="mt-8 mb-4 w-full bg-gray-50 p-4 border rounded ">
          <h3 className="text-lg font-bold mb-4">Conversation History</h3>
          <div className="space-y-2">
            {messages.filter((msg) => msg.role !== 'system').map((msg, index) => (
              <div key={index} className={`p-2 rounded ${msg.role === 'user' ? 'bg-blue-100' : 'bg-green-100'}`}>
                <strong>{msg.role === 'user' ? 'You' : 'Assistant'}: </strong>
                <span>{msg.content}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Input Form */}
        <form onSubmit={handleSubmit} className="w-full">
          <label htmlFor="prompt" className="block font-medium text-gray-700">
            Enter your Message:
          </label>
          <textarea
            id="prompt"
            rows={4}
            className="w-full p-2 border rounded mt-2"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your message here..."
          />
          <button type="submit" className="mt-4 bg-blue-500 text-white rounded py-2 px-4" disabled={loading}>
            {loading ? 'Loading...' : 'Send Message'}
          </button>
        </form>
      </div>

      {/* Right Side: JSON Data Display */}
      <div className="w-1/3 p-6 bg-white">
        <h2 className="text-xl font-bold mb-4">JSON Data</h2>
        <pre className="bg-gray-200 p-4 rounded">{JSON.stringify(dataReceived, null, 2)}</pre>
      </div>
    </div>
  );
};

export default TestPromptScreen;
