// store/reducers/onboardingSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchQuery, fetchMutation } from '../../helpers'; // Your API helper functions

// Example GraphQL queries and mutations
const GET_ONBOARDING_DATA = `
 query MyQuery($email_id: String = "") {
  vocallabs_client(where: {email_id: {_eq: $email_id}}) {
    company_brief
    company_name
    email_id
    industry
    id
  }
}
`;

const CREATE_ONBOARDING_DATA = `
 mutation MyMutation($company_name: String = "", $email_id: String = "", $industry: String = "", $company_brief: String = "") {
  insert_vocallabs_client_one(object: {company_name: $company_name, email_id: $email_id, industry: $industry, company_brief: $company_brief}) {
    company_brief
    company_name
    email_id
    id
  }
}
`;

// Initial state for onboarding
const storedClientData = localStorage.getItem('clientDetails');
let initialClientDetails;

try {
  initialClientDetails = storedClientData ? JSON.parse(storedClientData) : {
    company_name: '',
    company_brief: '',
    industry: '',
    email_id: '',
    id:''
  };
  console.log("initialClientDetails", initialClientDetails)
} catch (error) {
  console.error('Error parsing client details from localStorage:', error);
  initialClientDetails = {
    company_name: '',
    company_brief: '',
    industry: '',
    email_id: '',
    id:''
  };
}

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    clientDetails: initialClientDetails,
    loading: false,
    error: null,
  },
  reducers: {
    setOnboardingData: (state, action) => {
      state.clientDetails = { ...state.clientDetails, ...action.payload };
      localStorage.setItem('clientDetails', JSON.stringify(state.clientDetails));
    },
    setLoading: (state, action) => {
      state.loading = action.payload;  // Set loading state
    },
    RemoveData: (state) => {
      state.clientDetails = {
        company_name: '',
        company_brief: '',
        industry: '',
        email_id: '',
        id:''
      };;
      localStorage.removeItem('clientDetails');
    },
    setError: (state, action) => {
      state.error = action.payload;  // Set error state
    },
  },
});

// The async action that fetches or creates onboarding data
export const saveOnboardingData = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));  // Start loading

    // Fetch client data by email
    const response = await fetchQuery(GET_ONBOARDING_DATA, { email_id: data.email_id });

    let clientDetails;
    console.log(response)
    if (response.vocallabs_client.length === 0) {
      // If client does not exist, create a new one
      const createResponse = await fetchMutation(CREATE_ONBOARDING_DATA, {
        company_name: data.company_name,
        email_id: data.email_id,
        industry: data.industry,
        company_brief: data.company_brief,
        id:data.id
      });
      clientDetails = createResponse.insert_vocallabs_client_one;
    } else {
      // If client exists, update the state
      clientDetails = response.vocallabs_client[0];
    }

    dispatch(setOnboardingData(clientDetails));
    localStorage.setItem('clientDetails', JSON.stringify(clientDetails));

  } catch (error) {
    dispatch(setError(error.message));  // Set error state
  } finally {
    dispatch(setLoading(false));  // Stop loading
  }
};

export const { setOnboardingData, setLoading, setError, RemoveData } = onboardingSlice.actions;
export default onboardingSlice.reducer;