// components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg'; // Adjust the path based on where the logo is located
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../store/reducers/authSlice'; // Import the signOut action
import { FaSignOutAlt } from 'react-icons/fa'; // Importing the SignOut icon from Font Awesome
import { SignOut, Pencil } from "@phosphor-icons/react";

const Navbar = () => {
    const isSignedIn = useSelector((state) => state.auth.isSignedIn); // Check if the user is signed in
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Function to handle sign-out
    const handleSignOut = () => {
      dispatch(signOut()); // Dispatch the sign-out action
      navigate('/signin'); // Redirect to the sign-in page
    };

  return (
<nav className="w-full bg-gray-900 p-4">
<div className="container mx-auto flex items-center justify-between">
        {/* Logo Section */}
        <Link to="/" className="flex-shrink-0 relative">
  <img src={logo} alt="Company Logo" className="h-12 w-80 -ml-20" /> {/* Use negative margin to move left */}
</Link>

        
        {/* If user is signed in, show Edit Profile and Sign Out buttons */}
        {isSignedIn && (
          <div className="flex items-center space-x-4"> {/* space-x-4 adds space between the buttons */}
            {/* Edit Profile Button */}
            <Link
              to="/edit-profile"  // Route to the Edit Profile page
              className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white text-black  px-4 py-2 rounded hover:brightness-90 flex items-center"
              // style={{ backgroundColor: '#FAAD4F' }}

            >
             <span className="mr-3">Edit Profile</span> 
              <Pencil size={24} />


            </Link>

            {/* Sign Out Button */}
            <button
              onClick={handleSignOut}
              className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white text-black px-4 py-2 rounded  flex items-center"
            >
              <span className="mr-3">Sign Out</span> {/* Text */}
              <SignOut size={24} />


              </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
