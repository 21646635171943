import React from 'react';

const ServiceFeedbackForm = ({ values, handleChange, handleBlur, errors, touched }) => (
  <div className="p-4 bg-gray-800 rounded shadow">
    <h3 className="text-xl font-bold mb-4 text-gray-200">Service Feedback Form</h3>

    {/* Feedback Points */}
    <label className="block mb-2 text-gray-200">Feedback Points</label>
    <textarea
      name="feedback_points"
      value={values.feedback_points}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.feedback_points && errors.feedback_points ? 'border-red-500' : ''
      }`}
      rows="2"
      placeholder="Enter feedback points"
    ></textarea>
    {touched.feedback_points && errors.feedback_points && (
      <span className="text-red-500">{errors.feedback_points}</span>
    )}

    {/* Service Info */}
    <label className="block mb-2 text-gray-200">Service Info</label>
    <textarea
      name="service_info"
      value={values.service_info}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.service_info && errors.service_info ? 'border-red-500' : ''
      }`}
      rows="2"
      placeholder="Enter service information"
    ></textarea>
    {touched.service_info && errors.service_info && (
      <span className="text-red-500">{errors.service_info}</span>
    )}

    {/* Service Name */}
    <label className="block mb-2 text-gray-200">Service Name</label>
    <input
      type="text"
      name="service_name"
      value={values.service_name}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.service_name && errors.service_name ? 'border-red-500' : ''
      }`}
      placeholder="Enter service name"
    />
    {touched.service_name && errors.service_name && (
      <span className="text-red-500">{errors.service_name}</span>
    )}

    {/* User Info Feedback */}
    <label className="block mb-2 text-gray-200">User Info</label>
    <input
      type="text"
      name="user_info_feedback"
      value={values.user_info_feedback}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.user_info_feedback && errors.user_info_feedback ? 'border-red-500' : ''
      }`}
      placeholder="Enter user information"
    />
    {touched.user_info_feedback && errors.user_info_feedback && (
      <span className="text-red-500">{errors.user_info_feedback}</span>
    )}
  </div>
);

export default ServiceFeedbackForm;
  