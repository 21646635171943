import React, { useState } from 'react';

const SinglePromptTestScreen = () => {
  const [role, setRole] = useState('user');
  const [prompt, setPrompt] = useState('');
  const [temperature, setTemperature] = useState(0.5); // Default value
  const [responseContent, setResponseContent] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return; // Prevent empty submissions
    setLoading(true);

    // Prepare the message based on the selected role
    const message = { role: role, content: prompt };

    // Prepare the API request payload
    const requestData = {
      messages: [message], // Send only one message
      temperature: parseFloat(temperature),
    };

    try {
      const res = await fetch('https://subspacellm.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-08-01-preview', {
        method: 'POST',
        headers: {
          'api-key': '4b50be6aed7149f2ae80e7bc3ad37723', // Replace with your API key
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await res.json();
      if (data && data.choices && data.choices.length > 0) {
        setResponseContent(data.choices[0].message.content); // Set the API response content
      } else {
        setResponseContent('No valid response received from the API.');
      }
    } catch (error) {
      console.error('Error fetching API:', error);
      setResponseContent('Failed to fetch response.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h2 className="text-xl font-bold mb-4">Test Single Prompt API</h2>

      <form onSubmit={handleSubmit} className="w-full max-w-xl">
        <label htmlFor="role" className="block text-xs font-medium text-gray-700">
          Role:
        </label>
        <select
          id="role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          className="w-full p-2 border rounded mb-4 text-sm"
        >
          <option value="user">User</option>
          <option value="assistant">Assistant</option>
          <option value="system">System</option>
        </select>

        <label htmlFor="prompt" className="block text-xs font-medium text-gray-700">
          Enter your prompt:
        </label>
        <textarea
          id="prompt"
          name="prompt"
          rows={4}
          className="w-full p-2 border rounded text-xs"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter your prompt here..."
        />

        <div className="mt-8">
          <label htmlFor="temperature" className="block text-xs font-medium text-gray-700">
            Temperature:
          </label>
          <input
            type="number"
            id="temperature"
            name="temperature"
            step="0.1"
            min="0"
            max="1"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
            className="w-full p-2 border rounded mt-1 text-sm"
            placeholder="Set the temperature (0 to 1)"
          />
        </div>

        <button
          type="submit"
          className="mt-4 bg-customOrange-500 text-white rounded-md py-2 px-4 text-sm"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Test Prompt'}
        </button>
      </form>

      <div className="mt-8 w-full max-w-xl bg-white p-4 border rounded">
        <h3 className="text-lg font-bold mb-4">API Response</h3>
        <div className="space-y-2">
          <p>{responseContent || 'No response yet.'}</p>
        </div>
      </div>
    </div>
  );
};

export default SinglePromptTestScreen;
