import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { fetchQuery, fetchMutation } from '../helpers/index'; // Adjust the import according to your file structure

// Define the GraphQL query to fetch prompts
const GET_PROMPTS = `
  query MyQuery {
    vocallabs_prompts {
      prompt
      purpose
    }
  }
`;

// Define the GraphQL mutation for updating prompts
const UPDATE_PROMPT_MUTATION = `
  mutation MyMutation($purpose: String = "", $prompt: String = "") {
    update_vocallabs_prompts_by_pk(pk_columns: { purpose: $purpose }, _set: { prompt: $prompt }) {
      prompt
      purpose
    }
  }
`;

const UpdatePrompts = () => {
  const [prompts, setPrompts] = useState([]);
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const [currentPrompt, setCurrentPrompt] = useState('');

  // Function to fetch prompts
  const fetchPrompts = async () => {
    try {
      const data = await fetchQuery(GET_PROMPTS);
      setPrompts(data.vocallabs_prompts);
    } catch (error) {
      console.error('Error fetching prompts:', error);
      alert('Failed to fetch prompts. Please try again.');
    }
  };

  useEffect(() => {
    fetchPrompts();
  }, []);

  // Function to handle purpose selection and set the current prompt
  const handlePurposeChange = (e) => {
    const purpose = e.target.value;
    setSelectedPurpose(purpose);

    // Find the prompt corresponding to the selected purpose
    const promptData = prompts.find((p) => p.purpose === purpose);
    setCurrentPrompt(promptData ? promptData.prompt : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedPurpose) {
      alert('Please select a purpose before updating.');
      return;
    }

    try {
      await fetchMutation(UPDATE_PROMPT_MUTATION, {
        purpose: selectedPurpose,
        prompt: currentPrompt,
      });
      alert(`Prompt for purpose "${selectedPurpose}" updated successfully!`);
      fetchPrompts(); // Refetch prompts after updating
    } catch (err) {
      console.error('Error updating prompt:', err);
      alert('Failed to update prompt. Please try again.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h2 className="text-2xl font-bold mb-4">Update Prompts</h2>
      
      {/* Dropdown to select purpose */}
      <select
        className="w-full max-w-xl mb-4 p-2 border rounded-md"
        value={selectedPurpose}
        onChange={handlePurposeChange}
      >
        <option value="">Select a purpose</option>
        {prompts.map((prompt) => (
          <option key={prompt.purpose} value={prompt.purpose}>
            {prompt.purpose}
          </option>
        ))}
      </select>

      {/* Textarea to update prompt */}
      <form onSubmit={handleSubmit} className="w-full max-w-3xl">
        <textarea
          value={currentPrompt}
          onChange={(e) => setCurrentPrompt(e.target.value)}
          rows={30}
          className="mt-1 block w-full border rounded-md p-2 border-gray-300"
          placeholder="Enter your prompt here..."
        />
        <button
          type="submit"
          className="mt-2 bg-customOrange-500 text-white rounded-md py-2 px-4"
        >
          Update Prompt
        </button>
      </form>
    </div>
  );
};

export default UpdatePrompts;
