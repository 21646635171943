import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { saveOnboardingData } from '../store/reducers/onboardingSlice'; // Import the Redux action
import { fetchMutation } from '../helpers'; // Helper for GraphQL mutation

// GraphQL mutation for updating profile
const UPDATE_CLIENT_MUTATION = `
  mutation MyMutation($id: uuid = "", $company_brief: String = "", $company_name: String = "", $industry: String = "") {
    update_vocallabs_client_by_pk(pk_columns: {id: $id}, _set: {company_brief: $company_brief, company_name: $company_name, industry: $industry}) {
      company_brief
      company_name
      id
      industry
    }
  }
`;

const EditProfile = () => {
  const dispatch = useDispatch();
  
  // Get client details and loading/error state from Redux
  const { clientDetails, loading, error } = useSelector((state) => state.onboarding);
console.log("clientDetails are", clientDetails)
  // Formik setup for form handling and validation
  const formik = useFormik({
    initialValues: {
      company_name: clientDetails.company_name || '',
      company_brief: clientDetails.company_brief || '',
      industry: clientDetails.industry || '',
    },
    enableReinitialize: true, // Reinitialize form with new values when clientDetails changes
    validationSchema: Yup.object({
      company_name: Yup.string().required('Company name is required'),
      company_brief: Yup.string().required('Company brief is required'),
      industry: Yup.string().required('Industry is required'),
    }),
    onSubmit: async (values) => {
      try {
        // Dispatch the update mutation
        const response = await fetchMutation(UPDATE_CLIENT_MUTATION, {
          id: clientDetails.id,
          company_name: values.company_name,
          company_brief: values.company_brief,
          industry: values.industry,
        });

        if (response.update_vocallabs_client_by_pk) {
          // Update the Redux state with the new client details
          dispatch(saveOnboardingData(response.update_vocallabs_client_by_pk));
          alert('Profile updated successfully!');
        } else {
          throw new Error('Failed to update profile');
        }
      } catch (error) {
        console.error('Error updating profile:', error);
        alert('Failed to update profile. Please try again.');
      }
    },
  });

  // Render loading, error, or form
  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">Error: {error}</div>;

  return (
    <div className="max-w-xl mx-auto p-8">
      <h2 className="text-2xl font-bold mb-4">Edit Profile</h2>
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        {/* Company Name */}
        <div>
          <label className="block mb-2">Company Name</label>
          <input
            type="text"
            name="company_name"
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`border p-2 w-full ${formik.touched.company_name && formik.errors.company_name ? 'border-red-500' : 'border-gray-300'}`}
            placeholder="Enter company name"
          />
          {formik.touched.company_name && formik.errors.company_name ? (
            <span className="text-red-500">{formik.errors.company_name}</span>
          ) : null}
        </div>

        {/* Company Brief */}
        <div>
          <label className="block mb-2">Company Brief</label>
          <textarea
            name="company_brief"
            value={formik.values.company_brief}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`border p-2 w-full ${formik.touched.company_brief && formik.errors.company_brief ? 'border-red-500' : 'border-gray-300'}`}
            rows="4"
            placeholder="Enter company brief"
          ></textarea>
          {formik.touched.company_brief && formik.errors.company_brief ? (
            <span className="text-red-500">{formik.errors.company_brief}</span>
          ) : null}
        </div>

        {/* Industry */}
        <div>
          <label className="block mb-2">Industry</label>
          <input
            type="text"
            name="industry"
            value={formik.values.industry}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`border p-2 w-full ${formik.touched.industry && formik.errors.industry ? 'border-red-500' : 'border-gray-300'}`}
            placeholder="Enter industry"
          />
          {formik.touched.industry && formik.errors.industry ? (
            <span className="text-red-500">{formik.errors.industry}</span>
          ) : null}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          disabled={!formik.isValid || formik.isSubmitting}
          className={`mt-4 px-4 py-2 rounded w-full ${formik.isValid && !formik.isSubmitting ? 'bg-customOrange-blackButton text-white hover:bg-customOrange-hoverColorButton  hover:text-black' : 'bg-gray-600 cursor-not-allowed'}`}
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditProfile;