const AppointmentsForm = ({ values, handleChange, handleBlur }) => (
  <div>
    {/* Availability Slots Field */}
    <label className="block mb-2 text-gray-200">Availability Slots</label>
    <input
      type="text"
      name="availability_slots"
      value={values?.availability_slots}
      onChange={handleChange}
      onBlur={handleBlur}
      className="border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400"
      placeholder="Enter availability slots"
    />

    {/* Booking Appointment With Field */}
    <label className="block mb-2 text-gray-200">Booking Appointment With</label>
    <input
      type="text"
      name="booking_appointment_with"
      value={values?.booking_appointment_with}
      onChange={handleChange}
      onBlur={handleBlur}
      className="border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400"
      placeholder="Enter booking details"
    />

    {/* User Info to be Taken */}
    <label className="block mb-2 text-gray-200">User Info to be Taken</label>
    <textarea
      name="user_info_to_be_taken"
      value={values?.user_info_to_be_taken}
      onChange={handleChange}
      onBlur={handleBlur}
      className="border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400"
      placeholder="Enter user info"
    />
  </div>
);

export default AppointmentsForm;
