
//   const apiKey = 'AIzaSyArmNJavKEDdcu2ZAOXurisKKtW8gjMffI'; // Replace with your API key
// Authorization: `Bearer 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`,

import React, { useState, useRef } from 'react';
import axios from 'axios';

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const [recordingUrl, setRecordingUrl] = useState(null);
  const [transcription, setTranscription] = useState('');
  const apiKey = 'AIzaSyArmNJavKEDdcu2ZAOXurisKKtW8gjMffI'; // Replace with your Google API key
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleTTS = async () => {
    const requestBody = {
      input: { text },
      voice: {
        languageCode: 'ta-IN',
        name: 'ta-IN-Standard-A',
        ssmlGender: 'FEMALE',
      },
      audioConfig: {
        audioEncoding: 'LINEAR16',
        sampleRateHertz: 16000,
      },
    };

    try {
      const response = await fetch(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.audioContent) {
        const audioBlob = new Blob([Uint8Array.from(atob(data.audioContent), c => c.charCodeAt(0))], {
          type: 'audio/wav',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      }
    } catch (error) {
      console.error('Error with TTS:', error);
    }
  };

  const startRecording = async () => {
    setIsRecording(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    const chunks = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(chunks, { type: 'audio/wav' });
      const recordingUrl = URL.createObjectURL(audioBlob);
      setRecordingUrl(recordingUrl);
    };

    mediaRecorderRef.current.start();
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };

  const handleTranscription = async (withTTS) => {
    const formData = new FormData();
    if (recordingUrl) {
      const recordingBlob = await fetch(recordingUrl).then((res) => res.blob());
      formData.append('file', recordingBlob, 'recording.wav');
    }
    if (withTTS && audioUrl) {
      const ttsBlob = await fetch(audioUrl).then((res) => res.blob());
      formData.append('file', ttsBlob, 'tts.wav');
    }
    formData.append('model', 'whisper-1');
    formData.append("language", "ta")


    try {
      const response = await axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
          headers: {
            Authorization: `Bearer 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`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setTranscription(response.data.text);
    } catch (error) {
      console.error('Error with transcription:', error);
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Text to Speech & Audio Recorder</h1>

      <textarea
        value={text}
        onChange={handleInputChange}
        rows={3}
        cols={50}
        placeholder="Enter text for TTS..."
        className="w-full p-2 mb-4 border rounded"
      />

      <button onClick={handleTTS} className="px-4 py-2 bg-blue-500 text-white rounded mb-4">
        Generate TTS Audio
      </button>

      {audioUrl && (
        <div className="mb-4">
          <h2 className="font-semibold">Google TTS Audio:</h2>
          <audio controls src={audioUrl} />
        </div>
      )}

      <button
        onClick={isRecording ? stopRecording : startRecording}
        className={`px-4 py-2 rounded ${isRecording ? 'bg-red-500' : 'bg-green-500'} text-white mb-4`}
      >
        {isRecording ? 'Stop Recording' : 'Start Recording'}
      </button>

      {recordingUrl && (
        <div className="mb-4">
          <h2 className="font-semibold">Recorded Audio:</h2>
          <audio controls src={recordingUrl} />
        </div>
      )}

      <button
        onClick={() => handleTranscription(true)}
        className="px-4 py-2 bg-purple-500 text-white rounded mb-4"
      >
        Send to Whisper with TTS
      </button>
      <button
        onClick={() => handleTranscription(false)}
        className="px-4 py-2 bg-purple-700 text-white rounded mb-4"
      >
        Send to Whisper without TTS
      </button>

      {transcription && (
        <div className="mt-4 p-4 bg-gray-200 rounded">
          <h3 className="font-bold">Transcription:</h3>
          <p>{transcription}</p>
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
