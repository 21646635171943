import React, { useState, useEffect, useRef } from "react";

const AudioStreaming = () => {
  const SAMPLE_RATE = 44100;  // 44.1kHz sample rate
        const CHUNK_DURATION = 0.1;   // 2 seconds per chunk
        const CHUNK_SIZE = SAMPLE_RATE * CHUNK_DURATION;  // Number of frames per chunk (88200 for 2 seconds)

        let audioContext;
        let audioContext2;
        let playTime = 0;
        let currentSourceBuffer = null;
        let mediaStream;
        let audioBuffer = [];  // To store audio data until 2 seconds are collected
        let audioBuffer2 = [];
        let ws;
        let isRecording = false;
        let pendingAudioSources = [];

        // document.getElementById('startBtn').addEventListener('click', startStreaming);
        // document.getElementById('stopBtn').addEventListener('click', stopStreaming);
        // document.getElementById('closeBtn').addEventListener('click', closeConnection);

        // Helper function to concatenate multiple ArrayBuffers
        function concatenateArrayBuffers(buffers) {
            let totalLength = buffers.reduce((sum, buf) => sum + buf.byteLength, 0);
            let tempBuffer = new Uint8Array(totalLength);
            let offset = 0;

            buffers.forEach(buffer => {
                tempBuffer.set(new Uint8Array(buffer), offset);
                offset += buffer.byteLength;
            });

            return tempBuffer.buffer;
        }

        // Start streaming audio
        async function startStreaming() {

            if (isRecording) return;

            // Open WebSocket connection
            ws = new WebSocket('ws://localhost:8080/ws?userId=918281810740&from=web');

            audioContext2 = new (window.AudioContext || window.webkitAudioContext)();
            playTime = audioContext2.currentTime;

            ws.binaryType = 'arraybuffer';

            ws.onopen = () => {
                console.log('WebSocket connection opened');
                // document.getElementById('closeBtn').disabled = false;
            };

            ws.onclose = () => {
                console.log('WebSocket connection closed');
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            ws.onmessage = async (event) => {
                if (typeof event.data === "string") {
                    const message = JSON.parse(event.data);

                    if (message.status === "DONE") {
                        console.log("File transfer complete.");
                        // Optional: Handle any post-transfer logic here
                    } else if (message.status === "CLEAR") {
                        console.log("Received CLEAR status, stopping audio...");

                        // Stop and clear all pending audio sources
                        pendingAudioSources.forEach(source => {
                            try {
                                source.stop(); // Stop the audio source
                            } catch (error) {
                                console.warn("Error stopping audio source:", error);
                            }
                        });

                        // Reset state
                        pendingAudioSources = []; // Clear the list of sources
                        playTime = 0; // Reset play time
                        currentSourceBuffer = null; // Clear reference to the current buffer
                    }
                } else if (typeof event.data === 'object') {
                    console.log("Receiving audio data...");

                    try {
                        const decodedData = await audioContext2.decodeAudioData(event.data);

                        const sourceBuffer = audioContext2.createBufferSource();
                        sourceBuffer.buffer = decodedData;
                        sourceBuffer.connect(audioContext2.destination);

                        // Store the source in the list to manage it later
                        pendingAudioSources.push(sourceBuffer);

                        // Schedule playback with a small buffer for smooth transitions
                        if (playTime < audioContext2.currentTime) {
                            playTime = audioContext2.currentTime + 0.1; // Add slight buffer
                        }
                        sourceBuffer.start(playTime);
                        playTime += decodedData.duration; // Update play time for next chunk

                        // Store reference to the current source buffer
                        currentSourceBuffer = sourceBuffer;

                        // Remove source from the list after it plays
                        sourceBuffer.onended = () => {
                            pendingAudioSources = pendingAudioSources.filter(src => src !== sourceBuffer);
                        };
                    } catch (error) {
                        console.error("Error decoding audio data:", error);
                    }
                } else {
                    console.log('Received message:', event.data);
                }
            };

            // Play the concatenated audio once all chunks are received
            function playFullAudio() {
                if (audioBuffer2.length === 0) return;

                let totalBuffer = concatenateArrayBuffers(audioBuffer2);
                audioContext2.decodeAudioData(totalBuffer)
                    .then(function(decodedData) {
                        let sourceBuffer = audioContext2.createBufferSource();
                        sourceBuffer.buffer = decodedData;
                        sourceBuffer.connect(audioContext2.destination);

                        // Play the audio at normal speed from the beginning
                        sourceBuffer.start(0);
                    })
                    .catch(function(error) {
                        console.error("Error decoding audio data:", error);
                    });
                audioBuffer2 = [];
            }

            // Get access to the microphone
            mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioContext = new (window.AudioContext || window.webkitAudioContext)({ sampleRate: SAMPLE_RATE });
            const audioInput = audioContext.createMediaStreamSource(mediaStream);
            
            // Create a ScriptProcessorNode to process audio in chunks
            const processor = audioContext.createScriptProcessor(4096, 1, 1);
            audioInput.connect(processor);
            processor.connect(audioContext.destination);

            // Handle the audio chunk processing
            processor.onaudioprocess = (event) => {
                if (!isRecording) return;
                const audioChunk = event.inputBuffer.getChannelData(0);  // Get audio data from the first channel

                // Append audio chunk to the buffer (float32)
                audioBuffer.push(...audioChunk);

                // If enough data is collected for 2 seconds (88200 frames), send it
                if (audioBuffer.length >= CHUNK_SIZE) {
                    sendAudioChunk();
                }
            };

            // Update UI and state
            isRecording = true;
            // document.getElementById('startBtn').disabled = true;
            // document.getElementById('stopBtn').disabled = false;
        }

        // Send the collected audio buffer as a 2-second chunk
        function sendAudioChunk() {
            // Convert Float32Array to Int16Array for WebSocket transmission
            const int16Array = new Int16Array(audioBuffer.length);
            for (let i = 0; i < audioBuffer.length; i++) {
                int16Array[i] = Math.min(1, Math.max(-1, audioBuffer[i])) * 32767;  // Convert from float (-1 to 1) to int16
            }

            // Send the chunk over WebSocket
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(int16Array.buffer);
                console.log('Sent 2-second audio chunk');
            }

            // Clear the buffer for the next chunk
            audioBuffer = [];
        }

        // Stop streaming audio
        function stopStreaming() {
            if (!isRecording) return;

            // Stop the media stream and WebSocket
            mediaStream.getTracks().forEach(track => track.stop());

            // Send a "STOP" action over the WebSocket
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ action: "STOP" }));
                console.log('Sent STOP action');
            }

            // Update UI and state
            isRecording = false;
            // document.getElementById('startBtn').disabled = false;
            // document.getElementById('stopBtn').disabled = true;
        }

        // Close WebSocket connection
        function closeConnection() {
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.close();
                console.log('WebSocket connection closed');
            }

            // Update UI
            // document.getElementById('startBtn').disabled = false;
            // document.getElementById('stopBtn').disabled = true;
            // document.getElementById('closeBtn').disabled = true;
        }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-bold mb-6">Audio Streaming</h1>
      <div className="space-x-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          onClick={startStreaming}
          disabled={isRecording}
        >
          Start Streaming
        </button>
        <button
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
          onClick={stopStreaming}
          disabled={!isRecording}
        >
          Stop Streaming
        </button>
        <button
          className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
          onClick={closeConnection}
          disabled={!isRecording}
        >
          Close Connection
        </button>
      </div>
    </div>
  );
};

export default AudioStreaming;
