import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import { signOut } from '../store/reducers/authSlice';
import { RemoveData } from "../store/reducers/onboardingSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [company_name, setCompanyName] = useState("");

  const tempCompanyName = useSelector((state) => state.onboarding?.clientDetails?.company_name || '');
  const campaigns = useSelector((state) => state.onboarding?.clientDetails?.campaigns || []); // Get campaigns from state

  useEffect(() => {
    console.log("Company Name from Onboarding:", tempCompanyName);
    setCompanyName(tempCompanyName);
  }, [tempCompanyName]);

  const handleCreateAgentClick = () => {
    navigate('/create-agent');
  };

  const handleCreateCampaignClick = () => {
    navigate('/create-campaign');
  };

  const handleChangeChatSettingsClick = () => {
    navigate('/change-chat-settings');
  };

  const handleSignOut = () => {
    dispatch(signOut());
    dispatch(RemoveData());
    navigate('/onboarding');
  };

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign-details/${campaignId}`); // Navigate to campaign details page
  };

  return (
    <div className="min-h-screen flex bg-gray-900 text-gray-200">
      <Sidebar />

      <div className="flex-1 p-4 relative">
        {company_name && (
          <div className="text-center mt-10">
            <div className="text-xl mb-4">Welcome to Vocal Labs, {company_name}. Please create or select an agent.</div>
          </div>
        )}

        {/* Tabs for Campaign Names */}
        {campaigns.length > 0 && (
          <div className="flex justify-center mb-6 space-x-4">
            {campaigns.map((campaign) => (
              <button
                key={campaign.id}
                onClick={() => handleCampaignClick(campaign.id)} // Handle campaign click
                className="bg-gray-700 text-gray-200 px-4 py-2 rounded hover:bg-gray-600"
              >
                {campaign.name} {/* Display campaign name */}
              </button>
            ))}
          </div>
        )}

        {/* Create Agent Button */}
        <div className="text-center mt-6">
          <button
            onClick={handleCreateAgentClick}
            className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white px-6 py-3 rounded"
          >
            <h1>Create Agent +</h1> 
          </button>
        </div>

        {/* Create Campaign Button */}
        <div className="text-center mt-4">
          <button
            onClick={handleCreateCampaignClick}
            className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white px-6 py-3 rounded"
          >
            <h1>Create Campaign +</h1>
          </button>
        </div>

        <div className="text-center mt-4">
          <button
            onClick={handleChangeChatSettingsClick}
            className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white px-6 py-3 rounded"
          >
            <h1>Manage Chat Settings</h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
