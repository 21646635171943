import React, { useState } from 'react';
import Groq from 'groq-sdk';

const groq = new Groq({apiKey:"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", dangerouslyAllowBrowser: true});
const GroqApiPage = () => {
  const [role, setRole] = useState("system"); // Default role
  const [message, setMessage] = useState("");
  const [model, setModel] = useState("llama3-8b-8192");
  const [temperature, setTemperature] = useState(0.5);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleApiCall = async () => {
    if (!message) {
      setError("Message is required!");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const apiResponse = await groq.chat.completions.create({
        messages: [
          {
            role: role,
            content: message,
          },
        ],
        model: model,
        temperature: parseFloat(temperature),
        max_tokens: 1024,
        top_p: 1,
        stop: null,
        stream: false,
        response_format: { type: "json_object" },

      });

      setResponse(apiResponse.choices[0]?.message?.content || 'No content returned');
    } catch (err) {
      setError('Error: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h1 className="text-2xl font-bold mb-4">Groq API Call Test</h1>
        
        {/* Role Selector */}
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="role">Role</label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          >
            <option value="system">System</option>
            <option value="user">User</option>
          </select>
        </div>
        
        {/* Message Input */}
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="message">Message</label>
          <textarea
            type="text"
            rows={30}
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
            placeholder="Enter the message"
            required
          />
        </div>
        
        {/* Model Selector */}
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="model">Model</label>
          <select
            id="model"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
          >
            <option value="llama3-8b-8192">llama3-8b-8192</option>
            <option value="llama-3.2-1b-preview">llama-3.2-1b-preview</option>
            <option value="gemma2-9b-it">gemma2-9b-it</option>
            <option value="mixtral-8x7b-32768">mixtral-8x7b-32768</option>

            {/* gemma2-9b-it mixtral-8x7b-32768
 */}
            {/* Add other models if available */}
          </select>
        </div>

        {/* Temperature Input */}
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="temperature">Temperature</label>
          <input
            type="number"
            id="temperature"
            value={temperature}
            onChange={(e) => setTemperature(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded p-2"
            min="0"
            max="1"
            step="0.1"
          />
        </div>

        {/* Submit Button */}
        <button
          onClick={handleApiCall}
          className="w-full bg-blue-500 text-white rounded p-2"
          disabled={loading}
        >
          {loading ? 'Calling API...' : 'Make API Call'}
        </button>

        {/* Error Message */}
        {error && (
          <div className="mt-4 p-2 border border-red-300 rounded bg-red-50 text-red-700">
            {error}
          </div>
        )}

        {/* Response Output */}
        {response && (
          <div className="mt-4 p-2 border border-gray-300 rounded bg-gray-50">
            <h2 className="font-semibold">Response:</h2>
            <pre>{response}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroqApiPage;
