import React from 'react';

const SalesForm = ({ values, handleChange, handleBlur, errors, touched }) => (
  <div className="p-4 bg-gray-800 rounded shadow">
    <h3 className="text-xl font-bold mb-4 text-gray-200">Sales Form</h3>

    {/* Service Selling */}
    <label className="block mb-2 text-gray-200">Service Selling</label>
    <input
      type="text"
      name="service_selling"
      value={values.service_selling}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.service_selling && errors.service_selling ? 'border-red-500' : ''
      }`}
      placeholder="Enter the service you're selling"
    />
    {touched.service_selling && errors.service_selling && (
      <span className="text-red-500">{errors.service_selling}</span>
    )}

    {/* Pain Point Solving */}
    <label className="block mb-2 text-gray-200">Pain Point Solving</label>
    <input
      type="text"
      name="pain_point_solving"
      value={values.pain_point_solving}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.pain_point_solving && errors.pain_point_solving ? 'border-red-500' : ''
      }`}
      placeholder="Enter the pain point you're solving"
    />
    {touched.pain_point_solving && errors.pain_point_solving && (
      <span className="text-red-500">{errors.pain_point_solving}</span>
    )}

    {/* User Info */}
    <label className="block mb-2 text-gray-200">User Info</label>
    <input
      type="text"
      name="user_info_sales"
      value={values.user_info_sales}
      onChange={handleChange}
      onBlur={handleBlur}
      className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
        touched.user_info_sales && errors.user_info_sales ? 'border-red-500' : ''
      }`}
      placeholder="Enter user information"
    />
    {touched.user_info_sales && errors.user_info_sales && (
      <span className="text-red-500">{errors.user_info_sales}</span>
    )}
  </div>
);

export default SalesForm;
