import React from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet to render the nested routes
import Navbar from './Navbar'; // Assuming Navbar is inside the components folder

const Layout = () => {
  return (
    <div>
      <Navbar /> {/* The common navigation bar for all routes */}
      <div className="content">
        <Outlet /> {/* This renders the specific route content */}
      </div>
    </div>
  );
};

export default Layout;