import { createSlice } from '@reduxjs/toolkit';

// Initialize agents from localStorage if available
let initialAgentsState = { agents: [] };

const storedAgents = JSON.parse(localStorage.getItem('agents'))?.agents;
if (storedAgents?.length) {
  initialAgentsState.agents = storedAgents;
}

const agentsSlice = createSlice({
  name: 'agents',
  initialState: initialAgentsState,
  reducers: {
    createAgent: (state, action) => {
      if (!Array.isArray(state.agents)) {
        state.agents = [];
      }

      const newAgent = {
        id: action.payload.id,
        ...action.payload,
      };
      state.agents.push(newAgent);

      // Persist agents to localStorage
      localStorage.setItem('agents', JSON.stringify(state));
    },
    updateAgentDetails: (state, action) => {
      const { id, newDetails } = action.payload;
      const agentIndex = state.agents.findIndex((agent) => agent.id === id);
      if (agentIndex !== -1) {
        state.agents[agentIndex] = {
          ...state.agents[agentIndex],
          ...newDetails,
        };
        localStorage.setItem('agents', JSON.stringify(state));
      }
    },
    loadAgents: (state, action) => {
      state.agents = action.payload;  // Load agents from the sign-in query response
      localStorage.setItem('agents', JSON.stringify(state));  // Persist to localStorage
    },
  },
});

export const { createAgent, updateAgentDetails, loadAgents } = agentsSlice.actions;
export default agentsSlice.reducer;