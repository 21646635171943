// store/authActions.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchQuery } from '../../helpers';

const GET_USER_QUERY = `
  query MyQuery($email_id: String = "") {
  vocallabs_client(where: {email_id: {_eq: $email_id}}) {
    company_brief
    company_name
    email_id
    id
  }
}

`;

export const signInUser = createAsyncThunk(
  'auth/signInUser',
  async (email, { rejectWithValue }) => {
    try {
      const response = await fetchQuery(GET_USER_QUERY, { email });
      if (response.users.length > 0) {
        return response.users[0]; // Assuming the user exists
      } else {
        throw new Error('User not found');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);