import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaEye, FaPencilAlt } from 'react-icons/fa'; // Importing the eye and pencil icons
import { SignOut, Pencil, Eye } from "@phosphor-icons/react";

const Sidebar = () => {
  const agents = useSelector((state) => state.agents); // Access agents array from Redux state
  const navigate = useNavigate();
  const [storedAgents, setStoredAgents] = useState([]);

  // Function to navigate to the Create Agent page
  const handleCreateAgent = () => {
    navigate('/create-agent'); // Navigate to the Create Agent page
  };

  useEffect(() => {
    // Load agents from localStorage when the component mounts
    let tempstoredAgents = JSON.parse(localStorage.getItem('agents'))?.agents;
    setStoredAgents(tempstoredAgents || []); // Set stored agents
    console.log("stored agents:", storedAgents.length);
  }, []);

  // Function to select an agent and navigate to its details
  const handleSelectAgent = (agentId) => {
    navigate(`/agent-details/${agentId.trim()}`);
  };

  return (
    <div className="w-1/6 bg-transparent text-gray-200 h-screen p-4 flex flex-col justify-between">
      {/* Title */}
      <div>
        <h2 className="text-lg font-bold pb-4">Your Agents</h2>

        {/* Create Agent Button */}
        <button
            className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white px-6 py-3 rounded"
            onClick={handleCreateAgent}
          // style={{ backgroundColor: '#FAAD4F' }}
        
        >
          Create Agent +
        </button>

        {/* Agents List */}
        <ul className="mt-6">
          {storedAgents && storedAgents.length > 0 ? (
            storedAgents.map((agent) => (
              <li
                key={agent.id}
                className="p-3 bg-transparent rounded mb-2 flex justify-between items-center cursor-pointer hover:bg-gray-600"
              >
                <span>{agent.name}</span>
                <div className="flex space-x-2">
                  {/* Eye Icon for Viewing */}
                
                  <Eye size={20} 
                  onClick={() => handleSelectAgent(agent.id)} // View action

                  />
                  {/* Pencil Icon for Editing */}
                  <Pencil size={20} />
                </div>
              </li>
            ))
          ) : (
            <li>No agents created yet.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;