import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import { fetchMutation } from '../helpers/index';
import { Phone, Trash } from '@phosphor-icons/react';
const INSERT_CAMPAIGN_MUTATION = `
  mutation MyMutation($agent_id: uuid = "", $lead_name_column: String = "", $lead_phone_number_column: String = "", $name: String = "", $client_id: uuid = "") {
    insert_vocallabs_campaigns_one(object: {agent_id: $agent_id, lead_name_column: $lead_name_column, lead_phone_number_column: $lead_phone_number_column, name: $name, client_id: $client_id}) {
      id
    }
  }
`;

const INSERT_LEAD_DETAILS_MUTATION = `
  mutation MyMutation($objects: [vocallabs_campaign_leads_details_insert_input!] = {}) {
    insert_vocallabs_campaign_leads_details(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

const CreateCampaign = () => {
  const [selectedAgent, setSelectedAgent] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const agents = useSelector((state) => state.agents?.agents || []);
  const clientDetails = useSelector((state) => state?.onboarding?.clientDetails);

  const handleAgentChange = (e) => {
    setSelectedAgent(e.target.value);
  };

  useEffect(() => {
    console.log("Client Details:", clientDetails);
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const updatedData = results.data.map((row) => ({ ...row, Status: '' }));
          setCsvData(updatedData);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    }
  };

  const handleEditChange = (index, key, value) => {
    const updatedCsvData = [...csvData];
    updatedCsvData[index][key] = value; // Update the specific cell
    setCsvData(updatedCsvData);
  };

  const handleCreateCampaign = async () => {
    const variables = {
      agent_id: selectedAgent,
      client_id: clientDetails?.id,
    };

    try {
      const campaignResponse = await fetchMutation(INSERT_CAMPAIGN_MUTATION, variables);
      console.log("Inserting campaign response:", campaignResponse);
      const campaignId = campaignResponse.insert_vocallabs_campaigns_one.id;

      const leadDetails = csvData.map((row) => ({
        campaign_id: campaignId,
        json_details: row,
      }));

      await fetchMutation(INSERT_LEAD_DETAILS_MUTATION, { objects: leadDetails });
      alert('Campaign and lead details created successfully!');
    } catch (error) {
      console.error('Error creating campaign:', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 text-gray-200 p-4">
      <h1 className="text-2xl mb-6">Create Campaign</h1>

      <div className="mb-4">
        <label className="mr-2">Campaign Name:</label>
        <input
          type="text"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
          className="bg-gray-700 text-gray-200 p-2 rounded"
        />
      </div>

      <div className="mb-4">
        <label className="mr-2">Select Agent:</label>
        <select
          value={selectedAgent}
          onChange={handleAgentChange}
          className="bg-gray-700 text-gray-200 p-2 rounded"
        >
          <option value="" disabled>Select an agent</option>
          {agents.map((agent) => (
            <option key={agent.id} value={agent.id}>
              {agent.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-4">
        <label className="mr-2">Upload CSV:</label>
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="bg-gray-700 text-gray-200 p-2 rounded"
        />
      </div>

      {/* Render CSV table */}
      {csvData.length > 0 && (
        <div className="overflow-x-auto w-full">
          <table className="min-w-full border-collapse border border-gray-700">
            <thead>
              <tr>
                {Object.keys(csvData[0]).map((header) => (
                  <th key={header} className="border border-gray-700 p-2 text-left">{header}</th>
                ))}
                <th className="border border-gray-700 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {csvData.map((row, index) => (
                <tr key={index} className="hover:bg-gray-800">
                  {Object.keys(row).map((key) => (
                    <td key={key} className="border border-gray-700 p-2">
                      <input
                        type="text"
                        value={row[key]}
                        onChange={(e) => handleEditChange(index, key, e.target.value)}
                        className="bg-gray-700 text-gray-200 rounded p-1 w-full"
                      />
                    </td>
                  ))}
                  <td className="border border-gray-700 p-2 flex space-x-2">
                    <button className="text-green-500 hover:text-green-400">
                      <Phone size={20} />
                    </button>
                    <button className="text-red-500 hover:text-red-400">
                      <Trash size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <button onClick={handleCreateCampaign} className="bg-blue-500 text-black px-4 py-2 rounded mt-4">
        Create Campaign
      </button>
    </div>
  );
};

export default CreateCampaign;

