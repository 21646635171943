import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { signIn } from '../store/reducers/authSlice';
import { setOnboardingData } from '../store/reducers/onboardingSlice';
import { fetchQuery } from '../helpers';
import { loadAgents } from '../store/reducers/agentsSlice';

const SIGN_IN_BY_EMAIL = `
  query MyQuery($email_id: String = "") {
    vocallabs_client(where: {email_id: {_eq: $email_id}}) {
      agents {
        id
        name
        purpose
        welcome_message
        client_id
        appointments_booking_templates {
          agent_id
          availability_slots
          booking_appointment_with
          id
          user_info_to_be_taken
        }
        orders_booking_templates {
          agent_id
          products_menu
          updated_at
          user_info
        }
        feedback_templates {
          agent_id
          feedback_points
          service_info
          service_name
          user_info
        }
        sales_templates {
          agent_id
          pain_point_being_solved
          selling_product_name
          user_info_needed
        }
        surveys_templates {
          agent_id
          survey_questions
          updated_at
          user_info
          intention_of_survey
        }
      }
      company_brief
      company_name
      created_at
      email_id
      id
      logging_disabled
      industry
      campaigns {
        id
        name
      }
    }
  }
`;

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Formik setup with validation schema
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      if (values.password === 'testlocalnetwork') {
        try {
          const response = await fetchQuery(SIGN_IN_BY_EMAIL, { email_id: values.email });
          const user = response.vocallabs_client[0];

          if (user) {
            dispatch(setOnboardingData(user));
            dispatch(loadAgents(user.agents));
            dispatch(signIn());
            navigate('/dashboard');
          } else {
            alert('User not found. Please check your email or sign up.');
          }
        } catch (error) {
          console.error('Error fetching user:', error);
          alert('An error occurred. Please try again.');
        }
      } else {
        alert('Incorrect password');
      }
    },
  });

  const handleRegisterClick = () => {
    navigate('/onboarding');
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-900 text-gray-200">
      <div className="bg-gray-800 p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Sign In</h2>

        <form onSubmit={formik.handleSubmit}>
          {/* Email Input Field */}
          <label className="block mb-2 text-gray-300">Email Address</label>
          <input
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`w-full p-3 rounded bg-gray-700 text-white mb-4 ${formik.touched.email && formik.errors.email ? 'border border-red-500' : ''}`}
            placeholder="Enter your email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-sm mb-4">{formik.errors.email}</div>
          ) : null}

          {/* Password Input Field */}
          <label className="block mb-2 text-gray-300">Password</label>
          <input
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`w-full p-3 rounded bg-gray-700 text-white mb-4 ${formik.touched.password && formik.errors.password ? 'border border-red-500' : ''}`}
            placeholder="Enter your password"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-500 text-sm mb-4">{formik.errors.password}</div>
          ) : null}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!formik.isValid}
            className={`w-full bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white text-black py-2 rounded transition duration-200 ${
              !formik.isValid ? 'bg-opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Sign In
          </button>
        </form>

        {/* Register Button */}
        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={handleRegisterClick}
            className="text-customOrange-400 hover:underline"
          >
            Don't have an account? Register
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
