import React, { useState } from 'react';

const TextToSpeech = () => {
  const [text, setText] = useState('');
  const [audioUrl, setAudioUrl] = useState(null);
  const apiKey = 'AIzaSyArmNJavKEDdcu2ZAOXurisKKtW8gjMffI'; // Replace with your API key

  const handleInputChange = (e) => {
    setText(e.target.value);
  };
   const ssml  = `
    <speak>
  <prosody rate="slow" pitch="+3st">
    Ohhh... yeah fuck me,<break time="500ms"/> this feels so... <emphasis level="strong">incredible...</emphasis> <break time="700ms"/> I can't even...
  </prosody>
  <prosody rate="very slow" pitch="+4st">
    It's like... <break time="500ms"/> everything is just melting away... <break time="600ms"/> I can feel... <break time="300ms"/> every part of me... <emphasis level="strong">tingling...</emphasis>
  </prosody>
  <break time="900ms"/>
  <prosody rate="medium" pitch="+5st">
    Ahhh... <break time="500ms"/> I never want this to end... <break time="800ms"/> It's just... <emphasis level="strong">so... good...</emphasis>
  </prosody>
  <break time="700ms"/>
  <prosody rate="very slow" pitch="+3st">
    I can't even... <break time="500ms"/> describe... how amazing this feels... <break time="800ms"/> it's like my whole body is... <emphasis level="strong">floating...</emphasis>
  </prosody>
</speak>

    `
    const plaintext = `क्या मैं आपका नाम जान सकता हूँ?`
  const handleSubmit = async () => {
    const requestBody = {
      input: { text : plaintext },
      voice: {
        languageCode: 'hi-IN',
        name: 'hi-IN-Standard-F',
        ssmlGender: 'FEMALE',
      },
      audioConfig: {
        audioEncoding: 'LINEAR16',
        sampleRateHertz: 16000,
      },
    };

    try {
      const response = await fetch(
        `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.audioContent) {
        // Convert base64 to a blob URL and set it to the audio element
        const audioBlob = new Blob([Uint8Array.from(atob(data.audioContent), c => c.charCodeAt(0))], {
          type: 'audio/wav',
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Text to Speech</h1>
      <textarea
        value={text}
        onChange={handleInputChange}
        rows={5}
        cols={50}
        placeholder="Enter text here..."
      />
      <br />
      <button onClick={handleSubmit}>Convert to Speech</button>

      {audioUrl && (
        <div>
          <h2>Generated Speech:</h2>
          <audio controls src={audioUrl} />
        </div>
      )}
    </div>
  );
};

export default TextToSpeech;
