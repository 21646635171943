import React, { useState, useEffect } from 'react';
import { Play, Pause } from '@phosphor-icons/react';

// Visualizer Component
const Visualizer = ({ isPlaying }) => {
  const barCount = 5; // Number of bars to display
  const bars = Array.from({ length: barCount }); // Create an array to map over

  const [heights, setHeights] = useState(new Array(barCount).fill(20)); // Initial heights for each bar

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        setHeights(heights.map(() => Math.random() * 30 + 20)); // Update heights randomly
      }, 200); // Update every 200ms for a smooth effect

      return () => clearInterval(interval); // Clean up interval on unmount or when isPlaying changes
    } else {
      setHeights(new Array(barCount).fill(20)); // Reset heights when not playing
    }
  }, [isPlaying, barCount]);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end', marginRight: '12px' }}>
      {bars.map((_, index) => (
        <div
          key={index}
          style={{
            backgroundColor: 'white',
            width: '6px',
            margin: '0 2px',
            height: heights[index] + 'px', // Use the heights state for each bar
            transition: 'height 0.2s ease-in-out',
            animation: isPlaying ? 'none' : 'none', // Disable animation while not playing
          }}
        />
      ))}
      <style jsx>{`
        @keyframes pulse {
          0% {
            height: 20px; // Starting height
          }
          100% {
            height: 60px; // Height when pulsing
          }
        }
      `}</style>
    </div>
  );
};



const CallLogs = ({ data }) => {
  const calls = data?.calls || [];
  const [isPlaying, setIsPlaying] = useState(null); // Store currently playing audio's index
  const [currentAudio, setCurrentAudio] = useState(null);

  const handlePlayPause = (url, index) => {
    // If the same audio is clicked
    if (currentAudio && currentAudio.src === url) {
      if (isPlaying === index) {
        currentAudio.pause(); // Pause the current audio
        setIsPlaying(null); // Update the state
      } else {
        currentAudio.play(); // Play the current audio
        setIsPlaying(index); // Update the state to reflect which audio is playing
      }
    } else {
      // If a new audio is clicked
      if (currentAudio) {
        currentAudio.pause(); // Pause any currently playing audio
      }

      const newAudio = new Audio(url);
      setCurrentAudio(newAudio);
      setIsPlaying(index); // Update state to show which audio is playing
      newAudio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });

      newAudio.onended = () => {
        setIsPlaying(null); // Reset playing state on audio end
      };

      // Reset the current audio state when the audio is stopped
      newAudio.onpause = () => {
        if (isPlaying === index) {
          setIsPlaying(null); // Reset playing state when audio is paused
        }
      };
    }
  };

  return (
    <div style={{ backgroundColor: '#1F2937', padding: '16px', borderRadius: '8px', color: '#E5E7EB' }}>
      <h2 className="text-lg font-semibold mb-4">Call Logs</h2>
      {calls.map((call, index) => (
        <div
          key={index}
          style={{
            marginBottom: '24px',
            padding: '16px',
            backgroundColor: '#2D3748',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            height: '80px', // Fixed height for each call log item
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <p><strong>From:</strong> +{call.phone_from}</p>
            <p><strong>To:</strong> +{call.phone_to}</p>
            <p><strong>Created At:</strong> {new Date(call.created_at).toLocaleString("en-IN", { timeZone: "Asia/Kolkata", hour12: true })}</p>
          </div>

          {call.recording_url && (
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => handlePlayPause(call.recording_url, index)}>
              {isPlaying === index ? (
                  <Pause size={28} color="white" />
                ) : (
                  <Play size={28} color="white" />
                )}
              <div style={{ marginLeft: '12px' }}>
              <Visualizer isPlaying={isPlaying === index} /> {/* Pass whether this call is playing */}

              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CallLogs;
