import React from 'react';

const SurveysForm = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <>
      <label className="block mb-2 text-gray-200">Intention of Survey</label>
      <textarea
        name="intention_of_survey"
        value={values.intention_of_survey}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.intention_of_survey && errors.intention_of_survey ? 'border-red-500' : ''
        }`}
        rows="2"
        placeholder="Enter the intention of the survey"
      ></textarea>
      {touched.intention_of_survey && errors.intention_of_survey && (
        <span className="text-red-500">{errors.intention_of_survey}</span>
      )}

      <label className="block mb-2 text-gray-200">User Info Needed</label>
      <input
        type="text"
        name="user_info_survey"
        value={values.user_info_survey}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.user_info_survey && errors.user_info_survey ? 'border-red-500' : ''
        }`}
        placeholder="Enter user info needed"
      />
      {touched.user_info_survey && errors.user_info_survey && (
        <span className="text-red-500">{errors.user_info_survey}</span>
      )}

      <label className="block mb-2 text-gray-200">Survey Questions</label>
      <textarea
        name="survey_questions"
        value={values.survey_questions}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.survey_questions && errors.survey_questions ? 'border-red-500' : ''
        }`}
        rows="4"
        placeholder="Enter survey questions (comma separated)"
      ></textarea>
      {touched.survey_questions && errors.survey_questions && (
        <span className="text-red-500">{errors.survey_questions}</span>
      )}
    </>
  );
};

export default SurveysForm;
