import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { fetchMutation, fetchQuery } from '../helpers';
import Sidebar from '../components/Sidebar';
import Tabs from '../components/Tabs';
import { updateAgentDetails } from '../store/reducers/agentsSlice';
import { CalendarCheck, ChatCircle, SealPercent, Bell, ClipboardText, Code, HandWaving, PhoneCall } from '@phosphor-icons/react';
// Import subcomponents
import AppointmentsForm from '../components/agents/AppointmentsForm';
import SurveysForm from '../components/agents/SurverysForm';
import TakeOrdersForm from '../components/agents/TakeOrdersForm';
import SalesForm from '../components/agents/SalesForm'; // New component
import ServiceFeedbackForm from '../components/agents/ServiceFeedbackForm'; // New component
import axios from 'axios';
// GraphQL mutation for updating agent details
const UPDATE_AGENT_MUTATION = `
mutation MyMutation($id: uuid = "", $name: String = "", $purpose: String = "", $welcome_message: String = "", $analytics_prompt: String = "", $agent_prompt: String = "", $inputs_needed: String = "", $language: String = "") {
  update_vocallabs_agent_by_pk(pk_columns: {id: $id}, _set: {name: $name, purpose: $purpose, welcome_message: $welcome_message, analytics_prompt: $analytics_prompt, agent_prompt: $agent_prompt, inputs_needed: $inputs_needed, language: $language}) {
    client_id
    id
    name
    purpose
    welcome_message
  }
}
`;

const AGENT_DETAILS_BY_ID = `
query MyQuery($id: uuid = "") {
  vocallabs_agent_by_pk(id: $id) {
    calls(limit: 10, order_by: {created_at: desc}) {
      conversation
      recording_url
      phone_from
      phone_to
      details_from_call
      created_at
    }
    purpose
    welcome_message
    language
    name
    inputs_needed
    is_logging_disabled
    analytics_prompt
    agent_prompt
  }
}

`

// New GraphQL mutation for inserting appointment-specific fields


const AgentDetails = () => {
  const { agentId } = useParams(); // Get agent ID from the URL params
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Transcript History'); // Tabs state
  const [agent, setAgent] = useState(null); // Initialize agent as null
  const [agentDetails, setAgentDetails] = useState({})
  const [phone_number, setPhoneNumber] = useState('')
  const [ttsProvider, setTTSProvider] = useState('google')
  const agents = useSelector((state) => state.agents.agents); // Access agents from Redux
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const logging_disabled = useSelector((state) => state.onboarding?.clientDetails?.logging_disabled || false); // Get campaigns from state
  console.log("is logging disabled:", logging_disabled)
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'hi', name: 'Hindi' },
    { code: 'zh', name: 'Chinese' },
    { code: 'ar', name: 'Arabic' },
    { code: 'ja', name: 'Japanese' },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handleChangeCall = (e) => {
    const { name, value } = e.target;
    if (name === 'ttsProvider') {
      setTTSProvider(value);
    } else if (name === 'phone_number') {
      setPhoneNumber(value);
    }
  };
  
  const getAgentDetails = async ()=>{
    const response = await fetchQuery(AGENT_DETAILS_BY_ID, {
      id: agentId
    });
    setAgentDetails(response?.vocallabs_agent_by_pk)
    console.log("response is", response)

  }
  // Find and set the selected agent
  useEffect(() => {
    if (agents.length > 0) {
      const agentHere = agents.find((agent) => agent.id === agentId); // Compare ID as string
      setAgent(agentHere);
      console.log("agent here", agentHere)
      getAgentDetails()
    }
  }, [agents, agentId]);

  // Yup validation schema for form
  const validationSchema = Yup.object({
    name: Yup.string().required('Agent name is required'),
    welcome_message: Yup.string().required('Welcome message is required'),
    purpose: Yup.string().required('Please select an agent purpose'),
    agent_prompt:Yup.string().required('Agent Prompt is required'),
    analytics_prompt:Yup.string().required('Analytics Prompt is required'),
    inputs_needed:Yup.string().required('Inputs needed is required'),
    language: Yup.string().required('Language is required'),

    // Conditional fields for appointments
    availability_slots: Yup.string().nullable(),
    booking_appointment_with: Yup.string().nullable(),
    user_info_to_be_taken: Yup.string().nullable(),

    sales_info: Yup.string().nullable(), // Sales Info

    feedback_info: Yup.string().nullable(), // Service Feedback Info
  });


  const purposes = [
    { name: 'Appointments', icon: CalendarCheck },
    { name: 'Service Feedback', icon: ChatCircle },
    { name: 'Sales', icon: SealPercent },
    { name: 'Reminders', icon: Bell },
    { name: 'Surveys', icon: ClipboardText },
    { name: 'api', icon: Code },
  ];
  
  // Formik setup for form handling and validation
  const formik = useFormik({
     initialValues: {
      name: agentDetails?.name || '',
      welcome_message: agentDetails?.welcome_message || '',
      purpose: agentDetails?.purpose || 'Appointments', // Default to Appointments if not available
      agent_prompt: agentDetails?.agent_prompt ||'',
      analytics_prompt: agentDetails?.analytics_prompt ||'',
      inputs_needed: agentDetails?.inputs_needed || '',
      language: agentDetails?.language || '',
      // Appointments Template Fields
      availability_slots: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.availability_slots : null) ?? null,
      booking_appointment_with: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.booking_appointment_with : null) ?? null,
      user_info_to_be_taken: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.user_info_to_be_taken : null) ?? null,
    
          // Appointments Template Fields
      availability_slots: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.availability_slots : '') || '',
      booking_appointment_with: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.booking_appointment_with : '') || '',
      user_info_to_be_taken: (agent?.appointments_booking_templates?.length > 0 ? agent.appointments_booking_templates[0]?.user_info_to_be_taken : '') || '',

      // Sales Template Fields
      sales_info: (agent?.sales_templates?.length > 0 ? agent.sales_templates[0]?.sales_info : '') || '', // Initialize sales info
      sales_target: (agent?.sales_templates?.length > 0 ? agent.sales_templates[0]?.sales_target : '') || '', // Add any other fields for sales if needed
      service_selling: (agent?.sales_templates?.length > 0 ? agent.sales_templates[0]?.selling_product_name : '') || '',
      pain_point_solving: (agent?.sales_templates?.length > 0 ? agent.sales_templates[0]?.pain_point_being_solved : '') || '',
      user_info_sales: (agent?.sales_templates?.length > 0 ? agent.sales_templates[0]?.user_info_needed : '') || '',

      // Service Feedback Template Fields
      service_info: (agent?.feedback_templates?.length > 0 ? agent.feedback_templates[0]?.service_info : '') || '', // Initialize feedback info
      feedback_rating: (agent?.feedback_templates?.length > 0 ? agent.feedback_templates[0]?.feedback_rating : '') || '', // Add any other fields for feedback if needed
      service_name: (agent?.feedback_templates?.length > 0 ? agent.feedback_templates[0]?.service_name : '') || '', // Add any other fields for feedback if needed
      user_info_feedback: (agent?.feedback_templates?.length > 0 ? agent.feedback_templates[0]?.user_info : '') || '', // Add any other fields for feedback if needed

      // Survey Template Fields
      survey_questions: (agent?.surveys_templates?.length > 0 ? agent.surveys_templates[0]?.survey_questions : '') || '',
      intention_of_survey: (agent?.surveys_templates?.length > 0 ? agent.surveys_templates[0]?.intention_of_survey : '') || '',
      survey_questions: (agent?.surveys_templates?.length > 0 ? agent.surveys_templates[0]?.survey_questions : '') || '',
      user_info_survey: (agent?.surveys_templates?.length > 0 ? agent.surveys_templates[0]?.user_info : '') || '',
      // Order Templates
      products_menu: (agent?.orders_booking_templates?.length > 0 ? agent.orders_booking_templates[0]?.products_menu : '') || '',
      user_info_orders: (agent?.orders_booking_templates?.length > 0 ? agent.orders_booking_templates[0]?.user_info : '') || '',

  // user_info_orders
    },    
    enableReinitialize: true, // Reinitialize form when agent changes
    validationSchema,
    onSubmit: async (values) => {
      try {
        // Update the general agent details
        const response = await fetchMutation(UPDATE_AGENT_MUTATION, {
          id: agent.id,
          name: values.name,
          purpose: values.purpose,
          welcome_message: values.welcome_message,
          agent_prompt: values.agent_prompt,
          analytics_prompt: values.analytics_prompt,
          inputs_needed: values.inputs_needed,
          language: values.language

        });

        // Check if an appointments booking template already exists
        const templateId = agent?.appointments_booking_templates[0]?.id;
        if (values.purpose === 'Appointments') {
          if (templateId) {
            // Template exists, update the existing template
            const updateResponse = await fetchMutation(`
              mutation MyMutation($id: uuid = "", $availability_slots: String = "", $booking_appointment_with: String = "", $user_info_to_be_taken: String = "") {
                update_vocallabs_appointments_booking_template_by_pk(pk_columns: {id: $id}, _set: {availability_slots: $availability_slots, booking_appointment_with: $booking_appointment_with, user_info_to_be_taken: $user_info_to_be_taken}) {
                  availability_slots
                  booking_appointment_with
                  user_info_to_be_taken
                }
              }`, 
              {
                id: templateId,
                availability_slots: values.availability_slots,
                booking_appointment_with: values.booking_appointment_with,
                user_info_to_be_taken: values.user_info_to_be_taken,
              }
            );

            if (!updateResponse.update_vocallabs_appointments_booking_template_by_pk) {
              throw new Error('Failed to update appointment details');
            }
          } 
        }

        // Handle other purposes
        if (values.purpose === 'Sales' && values.sales_info) {
          // Insert or update sales info as needed
        }

        if (values.purpose === 'Service Feedback' && values.feedback_info) {
          // Insert or update feedback info as needed
        }

        if (values.purpose === 'Reminders') {
          // Insert or update reminders info as needed
        }

        // Dispatch to update agent in local Redux state after successful mutation
        dispatch(updateAgentDetails({
          id: agent.id,
          newDetails: values,
        }));
        alert('Agent updated successfully!');
      } catch (error) {
        console.error('Error updating agent:', error);
        alert('Failed to update agent. Please try again.');
      }
    },
  });
  const [success, setSuccess] = useState(false);

  const handleCallSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
    setSuccess(false); // Reset success state when submitting the request
  
    const data = {
      language: agentDetails?.language || 'en', // default to 'en' if not set
      number: phone_number, // Make sure this matches your state variable
      tts_provider: ttsProvider, // Make sure this matches your state variable
      agent_id: agent?.id,
    };
  
    try {
      const result = await axios.post('https://api.vocallabs.ai/create-call-via-api', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      if (result.status === 200) {
        setSuccess(true);
      } else {
        setError('Error: Unexpected response status');
      }
    } catch (err) {
      setError('Error: ' + err.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleAgentCall=()=>{
    setIsModalOpen(true)
  }
  // Handle case when no agent is selected or agent not found
  if (!agent) {
    return <div className="text-gray-200">No agent found or selected. Please go back and select an agent.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-gray-200">
      <div className="flex">
        {/* Sidebar */}
        <Sidebar />

        {/* Main Content (Agent Details) */}
        <div className="flex-1 p-8">
          {/* Header Section */}
          <div className="flex items-center justify-between mb-4">
  <h1 className="text-3xl font-bold">Edit Agent Details</h1>
  <button
    onClick={handleAgentCall}
    className="bg-customOrange-button text-black flex hover:bg-customOrange-hoverButton hover:text-white hover:bg-opacity-90 py-3 px-4 rounded"
  >
    Place a call <PhoneCall size={24} className="ml-2" />
  </button>
</div>
          {/* Form Section */}
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            {/* Agent Name Field */}
            <div>
              <label className="block mb-2">Agent Name</label>
              <input
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border p-2 w-full bg-gray-800 text-gray-200 ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-700'}`}
                placeholder="Enter agent name"
              />
              {formik.touched.name && formik.errors.name ? <span className="text-red-500">{formik.errors.name}</span> : null}
            </div>

            {/* Agent Purpose Tabs */}
            <div>
              <h4 className="mb-2">Agent Purpose</h4>
              <div className="overflow-x-auto flex space-x-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-700">
              {purposes.map(({ name, icon: Icon }) => (
              <button
                key={name}
                disabled
                type="button"
                onClick={() => formik.setFieldValue('purpose', name)}
                className={`px-4 py-2 rounded whitespace-nowrap flex items-center space-x-2 font-semibold ${
                  formik.values.purpose === name ? 'bg-customOrange-button text-black' : 'bg-gray-800 text-gray-200'
                }`}
              >
                <span>{name}</span>
                <Icon size={24} />
              </button>
            ))}
              </div>
              {formik.touched.purpose && formik.errors.purpose && <span className="text-red-500">{formik.errors.purpose}</span>}
            </div>

            {/* Conditionally render forms based on the selected purpose */}
            {formik.values.purpose === 'Appointments' && (
              <AppointmentsForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )}

            {formik.values.purpose === 'Surveys' && (
              <SurveysForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )}

            {formik.values.purpose === 'Take Orders' && (
              <TakeOrdersForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )}

            {formik.values.purpose === 'Sales' && (
              <SalesForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )}

            {formik.values.purpose === 'Service Feedback' && (
              <ServiceFeedbackForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )}

            {/* {formik.values.purpose === 'Reminders' && (
              <RemindersForm
                values={formik.values}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                touched={formik.touched}
                errors={formik.errors}
              />
            )} */}

            {/* Welcome Message */}
            <div>
              <label className="block mb-2 flex items-center">Agent Welcome Message <HandWaving size={24} /></label>
              <input
                type="text"
                name="welcome_message"
                value={formik.values.welcome_message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`border p-2 w-full bg-gray-800 text-gray-200 ${formik.touched.welcome_message && formik.errors.welcome_message ? 'border-red-500' : 'border-gray-700'}`}
                placeholder="Enter welcome message"
              />
              {formik.touched.welcome_message && formik.errors.welcome_message && <span className="text-red-500">{formik.errors.welcome_message}</span>}
            </div>
            <div className="mb-4">
            <label htmlFor="agentPrompt" className="block mb-2 font-medium ">Agent Prompt</label>
            <textarea
              id="agent_prompt"
              value={formik.values.agent_prompt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border p-2 w-full bg-gray-800 text-gray-200 ${formik.touched.agent_prompt && formik.errors.agent_prompt ? 'border-red-500' : 'border-gray-700'}`}
                
              rows={25}
              placeholder="Enter agent prompt"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="analyticsPrompt" className="block mb-2 font-large">Analytics Prompt</label>
            <textarea
              id="analytics_prompt"
              value={formik.values.analytics_prompt}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border p-2 w-full bg-gray-800 text-gray-200 ${formik.touched.analytics_prompt && formik.errors.analytics_prompt ? 'border-red-500' : 'border-gray-700'}`}   
              rows={11}
              placeholder="Enter analytics prompt"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="inputs_needed" className="block mb-2 font-medium">Inputs Needed</label>
            <textarea
              id="inputs_needed"
              value={formik.values.inputs_needed}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`border p-2 w-full bg-gray-800 text-gray-200 ${formik.touched.inputs_needed && formik.errors.inputs_needed ? 'border-red-500' : 'border-gray-700'}`}
  
              rows={11}
              placeholder="Enter inputs needed"
            />
          </div>
                <div className="container mx-auto p-6 ">
            <h2 className="text-2xl font-bold mb-4">Select Language</h2>
            <select
                name='language'
                value={formik.values.language}
                onChange={formik.handleChange}
                className="mt-2 p-2 border rounded w-full bg-gray-800 text-gray-200"
            >
                <option value="">Select a Language</option>
                {languages.map((language) => (
                <option key={language.code} value={language.code}>
                    {language.name}
                </option>
                ))}
            </select>
            </div>

          

            {/* Save Button */}
            <button
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
            className={`mt-4 px-4 py-2 rounded w-full ${formik.isValid && !formik.isSubmitting ? 'bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white hover:bg-opacity-90' : 'bg-gray-600 cursor-not-allowed'}`}
          >
            Save Agent
          </button>
          </form>

          {/* Tabs Section */}
          <div className="w-full mt-8">
            {!logging_disabled? <Tabs activeTab={activeTab} setActiveTab={setActiveTab} agentDetails = {agentDetails} /> : null}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 text-gray-200">
          <div className=" rounded-lg p-6 w-80">
            <h2 className="text-xl font-bold mb-4">Enter Call Details</h2>
            <form onSubmit={handleCallSubmit}>
              {/* TTS Provider Input */}
              <div className="mb-4">
                <label className="block font-medium  mb-2" htmlFor="ttsProvider">
                  TTS Provider
                </label>
                <input
                  type="text"
                  id="ttsProvider"
                  name="ttsProvider"
                  value={ttsProvider}
                  onChange={handleChangeCall}

                  className="w-full p-2 border rounded font-medium text-gray-700"
                  placeholder="e.g., Google, Azure"
                  required
                />
              </div>

              {/* Phone Number Input */}
              <div className="mb-4">
                <label className="block font-semibold mb-2" htmlFor="phone_number">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone_number"
                  name="phone_number"
                  value={phone_number}
                  onChange={handleChangeCall}
                  className="w-full p-2 border rounded font-medium text-gray-700"
                  placeholder="e.g., +1234567890"
                  required
                />
              </div>

              {/* Submit Button */}
              <button
      type="submit"
      className="bg-customOrange-button text-black text-center flex hover:bg-customOrange-hoverButton hover:text-white hover:bg-opacity-90 py-2 px-4 rounded w-full"
      disabled={loading} // Disable button when loading
    >
      {loading ? (
        "Loading..." // Text when the request is in progress
      ) : success ? (
        "Successful!" // Text when the request was successful
      ) : (
        "Place Call" // Default text before any action
      )}
    </button>
            </form>

            {/* Close Button */}
            <button
              onClick={() => setIsModalOpen(false)}
              className=" font-semibold mt-4 w-full"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
    
  );
};

export default AgentDetails;
