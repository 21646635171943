import React from 'react';
import { FileText, ChartLineUp, Phone, Gear } from '@phosphor-icons/react';
import TranscriptionHistory from './Tabs/TranscriptionHistory';
import CallLogs from './Tabs/CallLogs';
import Analytics from './Tabs/Analytics';

const Tabs = ({ activeTab, setActiveTab, agentDetails }) => {
  const tabs = [
    { name: 'Transcript History', icon: FileText },
    { name: 'Analytics', icon: ChartLineUp },
    { name: 'Call Logs', icon: Phone },
    { name: 'Actions', icon: Gear },
  ];

  return (
    <div className="mt-4">
      <div className="flex space-x-4">
        {tabs.map(({ name, icon: Icon }) => (
          <button
            key={name}
            onClick={() => setActiveTab(name)}
            className={`px-4 py-2 rounded flex items-center space-x-2 ${
              activeTab === name ? 'bg-customOrange-button text-black' : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
            }`}
          >
            <Icon size={20} />
            <span>{name}</span>
          </button>
        ))}
      </div>

      <div className="mt-4 bg-gray-800 p-4 rounded shadow text-gray-200">
        {activeTab === 'Transcript History' && <TranscriptionHistory data={agentDetails} />}
        {activeTab === 'Analytics' && <Analytics data={agentDetails} />}
        {activeTab === 'Call Logs' && <CallLogs data={agentDetails} />}
        {activeTab === 'Actions' && <div>Actions Tab Content</div>}
      </div>
    </div>
  );
};

export default Tabs;
