import React from 'react';
import { useFormikContext } from 'formik';

const DataGatheringForm = () => {
  const { values, handleChange, handleBlur, touched, errors } = useFormikContext();

  return (
    <>
      <label className="block mb-2 text-gray-200">Data Points to Gather</label>
      <input
        type="text"
        name="data_points"
        value={values.data_points}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.data_points && errors.data_points ? 'border-red-500' : ''
        }`}
        placeholder="Enter data points to gather"
      />
      {touched.data_points && errors.data_points && (
        <span className="text-red-500">{errors.data_points}</span>
      )}

      <label className="block mb-2 text-gray-200">Values of Data Points (Examples)</label>
      <textarea
        name="data_point_values"
        value={values.data_point_values}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.data_point_values && errors.data_point_values ? 'border-red-500' : ''
        }`}
        rows="3"
        placeholder="Enter possible values of the data points (e.g., age: 18-65, location: New York, etc.)"
      ></textarea>
      {touched.data_point_values && errors.data_point_values && (
        <span className="text-red-500">{errors.data_point_values}</span>
      )}

      <label className="block mb-2 text-gray-200">User Info Needed</label>
      <input
        type="text"
        name="user_info_data"
        value={values.user_info_data}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.user_info_data && errors.user_info_data ? 'border-red-500' : ''
        }`}
        placeholder="Enter user info needed"
      />
      {touched.user_info_data && errors.user_info_data && (
        <span className="text-red-500">{errors.user_info_data}</span>
      )}
    </>
  );
};

export default DataGatheringForm;
