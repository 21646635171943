import React from 'react';

const TakeOrdersForm = ({ values, handleChange, handleBlur, touched, errors }) => {
  return (
    <>
      <label className="block mb-2 text-gray-200">Order Menu</label>
      <textarea
        name="products_menu"
        value={values.products_menu}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.products_menu && errors.products_menu ? 'border-red-500' : ''
        }`}
        rows="2"
        placeholder="Enter the order menu"
      ></textarea>
      {touched.products_menu && errors.products_menu && (
        <span className="text-red-500">{errors.products_menu}</span>
      )}

      <label className="block mb-2 text-gray-200">User Info</label>
      <input
        type="text"
        name="user_info_orders"
        value={values.user_info_orders}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 border-gray-600 placeholder-gray-400 ${
          touched.user_info_orders && errors.user_info_orders ? 'border-red-500' : ''
        }`}
        placeholder="Enter user information"
      />
      {touched.user_info_orders && errors.user_info_orders && (
        <span className="text-red-500">{errors.user_info_orders}</span>
      )}
    </>
  );
};

export default TakeOrdersForm;
