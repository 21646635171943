import axios from "axios";
// Function to execute GraphQL queries
async function fetchGraphQL(query, variables = {}, operationName = null) {
  const result = await axios.post(
    "https://hasuraapi.travelr.club/v1/graphql",

    {
      query,
      variables,
      operationName,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "x-hasura-admin-secret": "legalpwd123",
      },
    }
  );

  return result.data;
}

export async function fetchQuery(query, variables) {
  const { errors, data } = await fetchGraphQL(query, variables);

  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}

// Function to fetch the example query
export async function fetchMutation(mutation, variables) {
  const { errors, data } = await fetchGraphQL(mutation, variables);
  if (errors) {
    console.error(errors);
    throw new Error(errors);
  } else {
    return data;
  }
}
