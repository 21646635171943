import { configureStore } from '@reduxjs/toolkit';
import onboardingReducer from './reducers/onboardingSlice';
import authReducer from './reducers/authSlice';
import agentsReducer from './reducers/agentsSlice';  // Import the agentsReducer

export const store = configureStore({
  reducer: {
    onboarding: onboardingReducer,
    auth: authReducer,
    agents: agentsReducer,  // Add the agents reducer
  },
});