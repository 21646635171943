import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchQuery } from '../helpers'; // Make sure this function is defined to fetch data
import { Phone, Trash, PencilSimple } from '@phosphor-icons/react';

const CAMPAIGN_QUERY = `
  query MyQuery($_eq: uuid = "") {
    vocallabs_campaigns(where: {id: {_eq: $_eq}}) {
      agent_id
      campaign_leads_details {
        campaign_id
        created_at
        id
        json_details
      }
      lead_name_column
      lead_phone_number_column
      name
    }
  }
`;

const CampaignDetails = () => {
  const { campaignId } = useParams(); // Get campaign ID from the URL
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const data = await fetchQuery(CAMPAIGN_QUERY, { _eq: campaignId });
        const campaign = data.vocallabs_campaigns[0];

        if (campaign) {
          setCampaignDetails(campaign);
          // Assuming json_details is an array of objects
          const leads = campaign.campaign_leads_details.map(detail => detail.json_details);
          setTableData(leads);

          // Set headers from the keys of the first lead's json_details
          if (leads.length > 0) {
            setHeaders(Object.keys(leads[0]));
          }
        }
      } catch (error) {
        console.error('Error fetching campaign details:', error);
      }
    };

    fetchCampaignDetails();
  }, [campaignId]);

  const handleEdit = (rowIndex) => {
    // Handle editing a row (you can implement this function)
  };

  const handleDelete = (rowIndex) => {
    // Handle deleting a row (you can implement this function)
  };


  const handleCall = () =>{
    
  }
  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 text-gray-200 p-4">
      <h1 className="text-2xl mb-6">Campaign Details</h1>
      {campaignDetails && (
        <>
          <h2 className="text-xl">{campaignDetails.name}</h2>
          <table className="min-w-full border border-gray-600 mt-4">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} className="border border-gray-600 p-2">{header}</th>
                ))}
                <th className="border border-gray-600 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td key={colIndex} className="border border-gray-600 p-2">
                      {row[header] || ''} {/* Ensure it handles undefined values */}
                    </td>
                  ))}
                  <td className="border border-gray-600 p-2 flex space-x-2">
                    <button onClick={() => handleEdit(rowIndex)} className="text-yellow-500">
                      <PencilSimple size={24} />
                    </button>
                    <button onClick={() => handleDelete(rowIndex)} className="text-red-500">
                      <Trash size={24} />
                    </button>
                    <button onClick={() => handleCall(rowIndex)} className="text-green-500">
                      <Phone size={24} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default CampaignDetails;
