// mutations.js

// GraphQL Mutation to create an agent
export const CREATE_AGENT_MUTATION = `
  mutation MyMutation($name: String = "", $purpose: String = "", $welcome_message: String = "", $client_id: uuid = "") {
    insert_vocallabs_agent_one(object: {name: $name, purpose: $purpose, welcome_message: $welcome_message, client_id: $client_id}) {
      id
      client_id
      name
      purpose
      welcome_message
    }
  }
`;

// Mutation for creating the order booking template
export const CREATE_ORDER_TEMPLATE_MUTATION = `
  mutation MyMutation($agent_id: uuid = "", $products_menu: String = "", $user_info: String = "") {
    insert_vocallabs_orders_booking_template_one(object: {agent_id: $agent_id, products_menu: $products_menu, user_info: $user_info}) {
      agent_id
      products_menu
      user_info
      id
    }
  }
`;

// Mutation for inserting appointment-specific fields
export const INSERT_APPOINTMENT_MUTATION = `
  mutation MyMutation($agent_id: uuid = "", $availability_slots: String = "", $booking_appointment_with: String = "", $user_info_to_be_taken: String = "") {
    insert_vocallabs_appointments_booking_template_one(object: {agent_id: $agent_id, availability_slots: $availability_slots, booking_appointment_with: $booking_appointment_with, user_info_to_be_taken: $user_info_to_be_taken}) {
      agent_id
      availability_slots
      booking_appointment_with
      created_at
      id
      updated_at
      user_info_to_be_taken
    }
  }
`;

// Mutation for inserting service feedback template
export const INSERT_FEEDBACK_TEMPLATE_MUTATION = `
  mutation MyMutation($agent_id: uuid = "", $feedback_points: String = "", $service_info: String = "", $service_name: String = "", $user_info: String = "") {
    insert_vocallabs_feedback_template_one(object: {agent_id: $agent_id, feedback_points: $feedback_points, service_info: $service_info, service_name: $service_name, user_info: $user_info}) {
      agent_id
      id
      feedback_points
      service_info
      service_name
      user_info
    }
  }
`;

export const INSERT_SALES_MUTATION = `
    mutation MyMutation($agent_id: uuid = "", $pain_point_being_solved: String = "", $selling_product_name: String = "", $user_info_needed: String = "") {
    insert_vocallabs_sales_template_one(object: {agent_id: $agent_id, pain_point_being_solved: $pain_point_being_solved, selling_product_name: $selling_product_name, user_info_needed: $user_info_needed}) {
        agent_id
        id
        pain_point_being_solved
        selling_product_name
        user_info_needed
    }
    }
`

export const INSERT_SURVEY_MUTATION = `
    mutation MyMutation($agent_id: uuid = "", $survey_questions: String = "", $user_info: String = "", $intention_of_survey: String="") {
    insert_vocallabs_surverys_template_one(object: {agent_id: $agent_id, survey_questions: $survey_questions, user_info: $user_info, intention_of_survey: $intention_of_survey}) {
        agent_id
        survey_questions
        user_info
        id
    }
  }
`