import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchQuery, fetchMutation } from '../helpers/index'; // Assuming these functions are imported

const ChangeChatSettingsPage = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [firstmessage, setFirstMessage] = useState('');
  const [prompt, setPrompt] = useState('');
  const [promptForActionCode, setPromptForActionCode] = useState('');
  const [promptForAnalytics, setPromptForAnalytics] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [handoff_prompt, sethandoff_prompt] = useState('');
  const [availableReps, setAvailableReps] = useState([]);
  // Fetch agents from Redux or another source
  const agents = useSelector((state) => state.agents?.agents || []);

  const GET_CHAT_SETTINGS = `
    query MyQuery($id: uuid = "") {
      vocallabs_chatSettings(where: {agent_id: {_eq: $id}}) {
        firstmessage
        prompt
        promptforactioncode
        promptforanalytics
        handoff_prompt
      }
    }
  `;
  const getChatwootAgents = async () => {
    try {
      const response = await fetch('https://ioapi.vocallabs.ai/api/openai/list-chatwoot-agents', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include any required authorization headers, e.g.:
          // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN'
        },
      });
  
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Chatwoot agents:', error.message);
      throw error;
    }
  };
  
  useEffect(() => {
    const fetchChatSettings = async () => {
      
      if (selectedAgent) {
        const repsoutput = await getChatwootAgents();
        setAvailableReps(repsoutput)
        try {
          const data = await fetchQuery(GET_CHAT_SETTINGS, { id: selectedAgent.id });
          if (data && data.vocallabs_chatSettings.length > 0) {
            const agentSettings = data.vocallabs_chatSettings[0];
            setFirstMessage(agentSettings.firstmessage || '');
            setPrompt(agentSettings.prompt || '');
            setPromptForActionCode(agentSettings.promptforactioncode || '');
            setPromptForAnalytics(agentSettings.promptforanalytics || '');
            sethandoff_prompt(agentSettings.handoff_prompt || '')
          }
        } catch (error) {
          console.error('Error fetching agent settings:', error);
        }
      }
    };

    fetchChatSettings();
  }, [selectedAgent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAgent) return;

    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    const UPDATE_CHAT_SETTINGS_MUTATION = `
      mutation MyMutation($_eq: uuid = "", $firstmessage: String = "", $prompt: String = "", $promptforactioncode: String = "", $promptforanalytics: String = "", $handoff_prompt: String = "") {
        update_vocallabs_chatSettings(where: {agent_id: {_eq: $_eq}}, _set: {firstmessage: $firstmessage, prompt: $prompt, promptforactioncode: $promptforactioncode, promptforanalytics: $promptforanalytics, handoff_prompt: $handoff_prompt}) {
          affected_rows
          returning {
            agent_id
            id
            prompt
            firstmessage
            promptforactioncode
            promptforanalytics
            handoff_prompt
          }
        }
      }
    `;

    try {
      const result = await fetchMutation(UPDATE_CHAT_SETTINGS_MUTATION, {
        _eq: selectedAgent.id,
        firstmessage,
        prompt,
        promptforactioncode: promptForActionCode,
        promptforanalytics: promptForAnalytics,
        handoff_prompt: handoff_prompt
      });

      if (result && result.update_vocallabs_chatSettings.affected_rows > 0) {
        setSuccessMessage('Settings updated successfully!');
      } else {
        setErrorMessage('Failed to update settings. Please try again.');
      }
    } catch (error) {
      console.error('Error updating chat settings:', error);
      setErrorMessage('Failed to update settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Change Chat Settings</h2>

      {/* Agent Selection */}
      <div className="mb-4">
        <label htmlFor="agent-select" className="block text-sm font-medium text-gray-700">Select Agent</label>
        <select
          id="agent-select"
          className="mt-2 p-2 border rounded w-full"
          onChange={(e) => {
            const selectedAgentId = e.target.value;
            const agent = agents.find(a => a.id === selectedAgentId);
            setSelectedAgent(agent);
          }}
          value={selectedAgent ? selectedAgent.id : ''}
        >
          <option value="">Select an agent</option>
          {agents.map((agent) => (
            <option key={agent.id} value={agent.id}>
              {agent.name}
            </option>
          ))}
        </select>
      </div>

      {/* Chat Settings Form */}
      {selectedAgent && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label htmlFor="firstmessage" className="block text-sm font-medium text-gray-700">First Message</label>
            <textarea
              id="firstmessage"
              className="mt-2 p-2 border rounded w-full"
              value={firstmessage}
              onChange={(e) => setFirstMessage(e.target.value)}
              rows={4}
              placeholder="Enter first message"
            />
          </div>
          <div>
            <b>Available Sales Agents:</b>
            <div>
     
            <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          textAlign: 'left',
        }}
      >
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>ID</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Name</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Email</th>
          </tr>
        </thead>
        <tbody>
          {availableReps.map((agent) => (
            <tr key={agent.id}>
              <td style={{ border: '1px solid black', padding: '8px' }}>{agent.id}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{agent.name}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{agent.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
          </div>
          <div className="mb-4">
            <label htmlFor="prompt" className="block text-sm font-medium text-gray-700">Prompt</label>
            <textarea
              id="prompt"
              className="mt-2 p-2 border rounded w-full"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              rows={34}
              placeholder="Enter prompt"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="promptForActionCode" className="block text-sm font-medium text-gray-700">Prompt for Action Code</label>
            <textarea
              id="promptForActionCode"
              className="mt-2 p-2 border rounded w-full"
              value={promptForActionCode}
              onChange={(e) => setPromptForActionCode(e.target.value)}
              rows={4}
              placeholder="Enter prompt for action code"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="promptForAnalytics" className="block text-sm font-medium text-gray-700">Prompt for Analytics</label>
            <textarea
              id="promptForAnalytics"
              className="mt-2 p-2 border rounded w-full"
              value={promptForAnalytics}
              onChange={(e) => setPromptForAnalytics(e.target.value)}
              rows={4}
              placeholder="Enter prompt for analytics"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="promptForHandoff" className="block text-sm font-medium text-gray-700">Prompt for Handoff</label>
            <textarea
              id="promptForHandoff"
              className="mt-2 p-2 border rounded w-full"
              value={handoff_prompt}
              onChange={(e) => sethandoff_prompt(e.target.value)}
              rows={4}
              placeholder="Enter prompt for analytics"
            />
          </div>

          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          {successMessage && <div className="text-green-500">{successMessage}</div>}

          <button type="submit" className="bg-blue-500 text-white rounded py-2 px-4" disabled={loading}>
            {loading ? 'Updating...' : 'Update Settings'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ChangeChatSettingsPage;
