// src/components/WebRTCClient.js
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const WebRTCClient = () => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [peerConnection, setPeerConnection] = useState(null);

  const handleStartCall = async () => {
    if (!peerConnection) {
        console.error("Peer connection is not initialized.");
        return;
    }

    try {
        // Create an offer
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        // Send the offer to the Go server
        const response = await axios.post('http://192.168.0.141:8080/offer', peerConnection.localDescription);
        const answer = response.data;
        await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
    } catch (error) {
        console.error("Error during the WebRTC handshake:", error);
    }
};

useEffect(() => {
    const pc = new RTCPeerConnection();
    setPeerConnection(pc);

    // Get the local media stream (e.g., microphone and camera)
    pc.onicecandidate = event => {
        if (event.candidate) {
            console.log("New ICE candidate:", event.candidate);
        }
    };
    
    pc.oniceconnectionstatechange = () => {
        console.log("ICE Connection State:", pc.iceConnectionState);
    };
    
    pc.onsignalingstatechange = () => {
        console.log("Signaling State:", pc.signalingState);
    };
    
    // Attempt to get media
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then(stream => {
            console.log("Got Media Stream:", stream);
            stream.getTracks().forEach(track => pc.addTrack(track, stream));
        })
        .catch(error => {
            console.error("Failed to get media:", error);
        });
    
    
}, []);


  return (
    <div>
      <h1>WebRTC Client</h1>
      <div>
        <h3>Local Video</h3>
        <video ref={localVideoRef} autoPlay muted></video>
      </div>
      <div>
        <h3>Remote Video</h3>
        <video ref={remoteVideoRef} autoPlay></video>
      </div>
      <button onClick={handleStartCall}>Start Call</button>
    </div>
  );
};

export default WebRTCClient;
