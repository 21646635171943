import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import OnboardingPage from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import CreateAgent from './pages/CreateAgent';
import AgentDetails from './pages/AgentDetails';
import SignIn from './pages/SignIn';  // Import SignIn component
import Layout from './components/Layout';
import EditProfile from './pages/EditProfile';
import UpdatePrompt from './pages/UpdatePrompt';
import TestPromptScreen from './pages/testprompt';
import VoiceTester from './pages/VoiceTestser';
import SinglePromptTestScreen from './pages/testsingleprompt';
import TestApiPage from './pages/testDynamicAgent';
import GroqApiPage from './pages/testGroqPage';
import TextToSpeech from './pages/testgoogletts';
import TextToSpeechAzure from './pages/testazuretts';
import AudioVADTesting from './pages/audiovadtesting';
import CreateCampaign from './pages/CreateCampagin';
import CampaignDetails from './pages/CampaignDetails';
import AudioRecorderWhisper from './pages/AudioWhispertranscription';
import WebRTCClient from './pages/webrtcTester';
import ChangeChatSettingsPage from './pages/chatSettings';
import ChangeCallSettingsPage from './pages/callSettings';
const AppRoutes = () => {
  const isSignedIn = useSelector((state) => state.auth.isSignedIn); // Get sign-in status from Redux

  return (
    <Router>

      <Routes>
        {/* Sign-In Route */}
        <Route element={<Layout/>}>
        <Route
          path="/signin"
          element={!isSignedIn ? <SignIn /> : <Navigate to="/dashboard" />}
        />
        
        {/* Onboarding Route */}
        <Route
          path="/onboarding"
          element={!isSignedIn ? <OnboardingPage /> : <Navigate to="/dashboard" />}
        />
   
        {/* Dashboard Route */}
        <Route
          path="/dashboard"
          element={isSignedIn ? <Dashboard /> : <Navigate to="/signin" />}
        />
        <Route
        path='/edit-profile'
        element={isSignedIn? <EditProfile/>:<Navigate to="/signin"/>}
        />
         <Route
        path='/update-prompts'
        element={<UpdatePrompt/>}
        />
        {/* Create Agent Route */}
        <Route
          path="/create-agent"
          element={isSignedIn ? <CreateAgent /> : <Navigate to="/signin" />}
        />
         <Route
          path="/create-campaign"
          element={isSignedIn ? <CreateCampaign /> : <Navigate to="/signin" />}
        />
        <Route
          path="/campaign-details/:campaignId"
          element={isSignedIn ? <CampaignDetails /> : <Navigate to="/signin" />}
        />
        <Route
        path="/test-via-whisper"
        element={<AudioRecorderWhisper/>}
        />
        <Route
        path="/change-chat-settings"
        element={<ChangeChatSettingsPage/>}
        />
        <Route
        path="/change-call-settings"
        element={<ChangeCallSettingsPage/>}
        />
        <Route
        path="/chat-screen"
        element={<TestPromptScreen/>}
        />
        <Route
        path="/test-dynamic-agent"
        element={<TestApiPage/>}
        />
         <Route
        path="/test-groq-agent"
        element={<GroqApiPage/>}
        />
        <Route
        path="/webrtc-client"
        element={<WebRTCClient/>}
        />
        <Route
        path="/test-single-prompt"
        element={<SinglePromptTestScreen/>}
        />

        <Route 
        path = "/test-tts"
        element= {<TextToSpeech/>}
        />
        <Route
        path="/test-audio"
        element={<VoiceTester/>}
        />
        <Route
        path="/test-voice-activity-detection"
        element={<AudioVADTesting/>}
        />
        {/* Agent Details Route */}
        <Route
          path="/agent-details/:agentId"
          element={isSignedIn ? <AgentDetails /> : <Navigate to="/signin" />}
        />

        {/* Default Route - Redirect based on authentication */}
        <Route
          path="/"
          element={isSignedIn ? <Navigate to="/dashboard" /> : <Navigate to="/signin" />}
        />
        <Route
        path= "/test-azure-tts"
        element={<TextToSpeechAzure/>}
        />
         </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;

