import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchQuery, fetchMutation } from '../helpers/index'; // Assuming these functions are imported

const ChangeCallSettingsPage = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentPrompt, setAgentPrompt] = useState('');
  const [analyticsPrompt, setAnalyticsPrompt] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [inputsNeeded, setInputsNeeded] = useState('');
  // Fetch agents from Redux or another source
  const agents = useSelector((state) => state.agents?.agents || []);
  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'hi', name: 'Hindi' },
    { code: 'zh', name: 'Chinese' },
    { code: 'ar', name: 'Arabic' },
    { code: 'ja', name: 'Japanese' },
  ];
  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
  };
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const GET_CALL_SETTINGS = `
    query MyQuery($id: uuid = "") {
  vocallabs_agent_by_pk(id: $id) {
    agent_prompt
    analytics_prompt
    welcome_message
    inputs_needed
    language
  }
}
  `;

  const UPDATE_CALL_SETTINGS_MUTATION = `
    mutation MyMutation($id: uuid = "", $agent_prompt: String = "", $analytics_prompt: String = "", $welcome_message: String = "", $inputs_needed: String = "", $language: String = "") {
  update_vocallabs_agent_by_pk(pk_columns: {id: $id}, _set: {agent_prompt: $agent_prompt, analytics_prompt: $analytics_prompt, welcome_message: $welcome_message, inputs_needed: $inputs_needed, language: $language}) {
    id
  }
}

  `;

  useEffect(() => {
    const fetchCallSettings = async () => {
      if (selectedAgent && selectedAgent.purpose === 'api') {
        try {
          const data = await fetchQuery(GET_CALL_SETTINGS, { id: selectedAgent.id });
          if (data && data.vocallabs_agent_by_pk) {
            const agentSettings = data.vocallabs_agent_by_pk;
            setAgentPrompt(agentSettings.agent_prompt || '');
            setAnalyticsPrompt(agentSettings.analytics_prompt || '');
            setWelcomeMessage(agentSettings.welcome_message || '');
            setInputsNeeded(agentSettings.inputs_needed || '');
            setSelectedLanguage(agentSettings.language || '');
          }
        } catch (error) {
          console.error('Error fetching agent settings:', error);
        }
      }
    };

    fetchCallSettings();
  }, [selectedAgent]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAgent) return;

    setLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const result = await fetchMutation(UPDATE_CALL_SETTINGS_MUTATION, {
        id: selectedAgent.id,
        agent_prompt: agentPrompt,
        analytics_prompt: analyticsPrompt,
        welcome_message: welcomeMessage,
        language: selectedLanguage,
        inputs_needed: inputsNeeded
      });

      if (result && result.update_vocallabs_agent_by_pk.id) {
        setSuccessMessage('Settings updated successfully!');
      } else {
        setErrorMessage('Failed to update settings. Please try again.');
      }
    } catch (error) {
      console.error('Error updating call settings:', error);
      setErrorMessage('Failed to update settings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Change Call Settings</h2>

      {/* Agent Selection */}
      <div className="mb-4">
        <label htmlFor="agent-select" className="block text-sm font-medium text-gray-700">Select Agent</label>
        <select
          id="agent-select"
          className="mt-2 p-2 border rounded w-full"
          onChange={(e) => {
            const selectedAgentId = e.target.value;
            const agent = agents.find(a => a.id === selectedAgentId && a.purpose === 'api');
            setSelectedAgent(agent);
          }}
          value={selectedAgent ? selectedAgent.id : ''}
        >
          <option value="">Select an agent</option>
          {agents
            .filter(agent => agent.purpose === 'api') // Only show agents with purpose 'api'
            .map((agent) => (
              <option key={agent.id} value={agent.id}>
                {agent.name}
              </option>
            ))}
        </select>
      </div>

      {/* Call Settings Form */}
      {selectedAgent && (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label htmlFor="agentPrompt" className="block text-sm font-medium text-gray-700">Agent Prompt</label>
            <textarea
              id="agentPrompt"
              className="mt-2 p-2 border rounded w-full"
              value={agentPrompt}
              onChange={(e) => setAgentPrompt(e.target.value)}
              rows={25}
              placeholder="Enter agent prompt"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="analyticsPrompt" className="block text-sm font-medium text-gray-700">Analytics Prompt</label>
            <textarea
              id="analyticsPrompt"
              className="mt-2 p-2 border rounded w-full"
              value={analyticsPrompt}
              onChange={(e) => setAnalyticsPrompt(e.target.value)}
              rows={11}
              placeholder="Enter analytics prompt"
            />
          </div>
                <div className="container mx-auto p-6">
            <h2 className="text-2xl font-bold mb-4">Select Language</h2>
            <select
                value={selectedLanguage}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
            >
                <option value="">Select a Language</option>
                {languages.map((language) => (
                <option key={language.code} value={language.code}>
                    {language.name}
                </option>
                ))}
            </select>
            </div>

          <div className="mb-4">
            <label htmlFor="inputsNeeded" className="block text-sm font-medium text-gray-700">Inputs Needed</label>
            <textarea
              id="inputsNeeded"
              className="mt-2 p-2 border rounded w-full"
              value={inputsNeeded}
              onChange={(e) => setInputsNeeded(e.target.value)}
              rows={11}
              placeholder="Enter Inputs needed"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="welcomeMessage" className="block text-sm font-medium text-gray-700">Welcome Message</label>
            <textarea
              id="welcomeMessage"
              className="mt-2 p-2 border rounded w-full"
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
              rows={4}
              placeholder="Enter welcome message"
            />
          </div>

          {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          {successMessage && <div className="text-green-500">{successMessage}</div>}

          <button type="submit" className="bg-blue-500 text-white rounded py-2 px-4" disabled={loading}>
            {loading ? 'Updating...' : 'Update Settings'}
          </button>
        </form>
      )}
    </div>
  );
};

export default ChangeCallSettingsPage;
