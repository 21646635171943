// store/authSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { signInUser } from '../actions/authActions';

const initialSignInStatus = localStorage.getItem('isSignedIn') === 'true';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isSignedIn: initialSignInStatus,
    userDetails: null,
    loading: false,
    error: null,
  },
  reducers: {
    signOut: (state) => {
      state.isSignedIn = false;
      localStorage.clear();
    },
    signIn: (state) => {
      state.isSignedIn = true;
      localStorage.setItem('isSignedIn', 'true'); // Persist sign-in status in localStorage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.isSignedIn = true;
        state.userDetails = action.payload;
        localStorage.setItem('isSignedIn', 'true');
        localStorage.setItem('userDetails', JSON.stringify(action.payload));
        state.loading = false;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { signIn,signOut } = authSlice.actions;
export default authSlice.reducer;