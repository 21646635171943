import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { createAgent } from '../store/reducers/agentsSlice';
import { useNavigate } from 'react-router-dom';
import { fetchMutation } from '../helpers';
import {
  CREATE_AGENT_MUTATION,
  CREATE_ORDER_TEMPLATE_MUTATION,
  INSERT_APPOINTMENT_MUTATION,
  INSERT_FEEDBACK_TEMPLATE_MUTATION,
  INSERT_SALES_MUTATION,
  INSERT_SURVEY_MUTATION,
} from '../graphql/mutations';

// Import specific forms for each purpose
import AppointmentsForm from '../components/agents/AppointmentsForm';
import TakeOrdersForm from '../components/agents/TakeOrdersForm';
import ServiceFeedbackForm from '../components/agents/ServiceFeedbackForm';
import SalesForm from '../components/agents/SalesForm';
import SurveysForm from '../components/agents/SurverysForm.js';
import DataGatheringForm from '../components/agents/DataGatheringForm';
import { CalendarCheck, ChatCircle, SealPercent,CurrencyDollar,  Bell, ClipboardText, Code, HandWaving } from '@phosphor-icons/react';

const CreateAgent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const client_id = useSelector((state) => state.onboarding?.clientDetails?.id);

  const purposes = [
    'Appointments',
    'Service Feedback',
    'Sales',
    'Reminders',
    'Surveys',
    'api'
  ];
  const purposeIcons = {
    Appointments: <CalendarCheck size={20} />,
    'Service Feedback': <ChatCircle size={20} />,
    Sales: <CurrencyDollar size={20} />,
    Reminders: <Bell size={20} />,
    Surveys: <ClipboardText size={20} />,
    api: <Code size={20} />,
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      welcome_message: '',
      purpose: purposes[0],
      products_menu: '',
      user_info_orders: '',
      availability_slots: '',
      booking_appointment_with: '',
      user_info_to_be_taken: '',
      feedback_points: '',
      service_info: '',
      service_name: '',
      user_info_feedback: '',
      service_selling: '',
      pain_point_solving: '',
      user_info_sales: '',
      intention_of_survey: '',
      user_info_survey: '',
      survey_questions: '',
      data_points: '',
      data_point_values: '',
      user_info_data: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().nullable(),
      welcome_message: Yup.string().nullable(),
      purpose: Yup.string().nullable(),
      products_menu: Yup.string().nullable(),
      user_info_orders: Yup.string().nullable(),
      availability_slots: Yup.string().nullable(),
      booking_appointment_with: Yup.string().nullable(),
      user_info_to_be_taken: Yup.string().nullable(),
      feedback_points: Yup.string().nullable(),
      service_info: Yup.string().nullable(),
      service_name: Yup.string().nullable(),
      user_info_feedback: Yup.string().nullable(),
      service_selling: Yup.string().nullable(),
      pain_point_solving: Yup.string().nullable(),
      user_info_sales: Yup.string().nullable(),
      intention_of_survey: Yup.string().nullable(),
      user_info_survey: Yup.string().nullable(),
      survey_questions: Yup.string().nullable(),
      data_points: Yup.string().nullable(),
      data_point_values: Yup.string().nullable(),
      user_info_data: Yup.string().nullable(),
    }),
    onSubmit: async (values) => {
      try {
        if (!client_id) {
          alert("Client ID is missing. Please try again.");
          return;
        }

        // Prepare mutation variables for agent creation
        const mutationVariables = {
          name: values.name,
          purpose: values.purpose,
          welcome_message: values.welcome_message,
          client_id,
        };

        // Create the agent first
        const response = await fetchMutation(CREATE_AGENT_MUTATION, mutationVariables);
        const newAgent = response.insert_vocallabs_agent_one;
        dispatch(createAgent(newAgent));

        // Handle "Take Orders" purpose
        if (values.purpose === 'Take Orders') {
          const orderTemplateVariables = {
            agent_id: newAgent.id,
            products_menu: values.products_menu,
            user_info: values.user_info_orders,
          };
          await fetchMutation(CREATE_ORDER_TEMPLATE_MUTATION, orderTemplateVariables);
        }

        // Handle "Appointments" purpose
        if (values.purpose === 'Appointments') {
          const appointmentVariables = {
            agent_id: newAgent.id,
            availability_slots: values.availability_slots,
            booking_appointment_with: values.booking_appointment_with,
            user_info_to_be_taken: values.user_info_to_be_taken,
          };
          await fetchMutation(INSERT_APPOINTMENT_MUTATION, appointmentVariables);
        }

        // Handle "Service Feedback" purpose
        if (values.purpose === 'Service Feedback') {
          const feedbackVariables = {
            agent_id: newAgent.id,
            feedback_points: values.feedback_points,
            service_info: values.service_info,
            service_name: values.service_name,
            user_info: values.user_info_feedback,
          };
          await fetchMutation(INSERT_FEEDBACK_TEMPLATE_MUTATION, feedbackVariables);
        }

        // Handle "Sales" purpose
        if (values.purpose === 'Sales') {
          const salesVariables = {
            agent_id: newAgent.id,
            selling_product_name: values.service_selling,
            pain_point_being_solved: values.pain_point_solving,
            user_info_needed: values.user_info_sales,
          };
          await fetchMutation(INSERT_SALES_MUTATION, salesVariables);
        }
        if (values.purpose === 'Surveys') {
          const surveyVariables = {
            agent_id: newAgent.id,
            survey_questions: values.survey_questions,
            user_info: values.user_info_survey,
            intention_of_survey: values.intention_of_survey
          };
          await fetchMutation(INSERT_SURVEY_MUTATION, surveyVariables);
        }

        // Navigate back to the Dashboard after successfully creating an agent
        navigate('/dashboard');
      } catch (error) {
        console.error("Error creating agent:", error);
        alert("An error occurred while creating the agent.");
      }
    },
  });

  return (
    <Formik {...formik}>
      <form onSubmit={formik.handleSubmit} className="p-12 bg-gray-800 text-gray-200 ">
        <h3 className="text-xl font-bold mb-4">Create New Agent</h3>

        {/* Agent Name Field */}
        <label className="block mb-2">Agent Name</label>
        <input
          type="text"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-700'}`}
          placeholder="Enter agent name"
        />
        {formik.touched.name && formik.errors.name && <span className="text-red-500">{formik.errors.name}</span>}

        {/* Horizontal Scrollable Agent Purpose Tabs */}
        <label className="block mb-2">Agent Purpose</label>
        <div className="flex space-x-4 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-600 mb-4">
          {purposes.map((purpose) => (
            
            <button
              key={purpose}
              type="button"
              onClick={() => formik.setFieldValue('purpose', purpose)}
              className={`px-4 py-2 rounded whitespace-nowrap flex items-center ${formik.values.purpose === purpose ? 'bg-customOrange-button text-black' : 'bg-gray-600 text-gray-200'}`}
            >
              {purpose} <span className="ml-2">{purposeIcons[purpose]}</span>
            </button>
          ))}
        </div>
        {formik.touched.purpose && formik.errors.purpose && (
          <span className="text-red-500">{formik.errors.purpose}</span>
        )}

        {/* Conditionally render forms based on purpose */}
        {formik.values.purpose === 'Appointments' && (
          <AppointmentsForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
          />
        )}
        {formik.values.purpose === 'Take Orders' && (
          <TakeOrdersForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            touched={formik.touched}
            errors={formik.errors}
          />
        )}
        {formik.values.purpose === 'Service Feedback' && (
          <ServiceFeedbackForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
          />
        )}
        {formik.values.purpose === 'Sales' && (
          <SalesForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
          />
        )}
        {formik.values.purpose === 'Surveys' && (
          <SurveysForm
            values={formik.values}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            errors={formik.errors}
            touched={formik.touched}
          />
        )}
        {formik.values.purpose === 'Data Gathering' && <DataGatheringForm />}

        {/* If the purpose is "Reminders", display a "Coming Soon" message */}
        {formik.values.purpose === 'Reminders' && (
          <div className="text-center bg-yellow-200 text-yellow-800 p-4 rounded">
            <strong>Coming Soon:</strong> The "Reminders" feature will be available soon.
          </div>
        )}

        {/* Welcome Message Field */}
        <label className="block mb-2 flex items-center">Welcome Message <HandWaving size={24}/></label>
        <input
          type="text"
          name="welcome_message"
          value={formik.values.welcome_message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`border p-2 w-full mb-2 bg-gray-700 text-gray-200 ${formik.touched.welcome_message && formik.errors.welcome_message ? 'border-red-500' : 'border-gray-700'}`}
          placeholder="Enter welcome message"
        />
        {formik.touched.welcome_message && formik.errors.welcome_message && (
          <span className="text-red-500">{formik.errors.welcome_message}</span>
        )}

       

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-customOrange-button text-black hover:bg-customOrange-hoverButton hover:text-white px-4 py-2 rounded mt-4"
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Create Agent
        </button>
      </form>
    </Formik>
  );
};

export default CreateAgent;
